<template>
  <component
    :is="tag"
    class="a"
    :to="to"
    :href="href"
    :class="{
      'a--disabled': disabled,
      'a--text': text,
      'a--underline': underline
    }"
    :target="blank ? '_blank' : null"
    @click="onClick"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    text: Boolean,
    href: String,
    to: String,
    blank: Boolean,
    block: Boolean,
    underline: Boolean
  },
  computed: {
    tag() {
      if (this.to) {
        return 'router-link'
      }

      return 'a'
    }
  },
  methods: {
    onClick(e) {
      if (this.disabled) {
        e.preventDefault()
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.a {
  font-size: inherit;
  color: #22c58b;
  text-decoration: none;
  cursor: pointer;

  &--disabled {
    color: get-color(text-4);
  }

  &--text {
    color: inherit;
  }

  &--underline {
    text-decoration: underline;
  }
}
</style>
