<template>
  <Main center>
    <Start>
      <template v-slot:header>
        <H h2>{{ getLocal(language, 'enterPhoneNumber.header') }}</H>

        <P class="phone__info">{{ getLocal(language, 'enterPhoneNumber.info') }}</P>
      </template>

      <template v-slot:main>
        <div class="phone__error">
          <Toast v-if="error" error>{{ error }}</Toast>
        </div>

        <div class="phone__input">
          <Input
            :label="getLocal(language, 'enterPhoneNumber.phoneNo.label')"
            placeholder="+1 (999) 999 - 9999"
            mask="+1 (###) ### - ####"
            type="tel"
            inputmode="tel"
            v-model:value="phoneNumber"
          />
          <P class="phone__compliance" small2>{{ getLocal(language, 'complianceSmsAlerts') }}</P>
        </div>

        <div class="phone__input">
          <Input
            v-if="prevRoute === '/otp'"
            :label="getLocal(language, 'enterPhoneNumber.ssn.label')"
            placeholder="SSN/ITIN"
            type="text"
            v-model:value="blurredSsn"
            ref="ssnInput"
            @focus=";(prevRoute = ''), (ssn = ''), (confirmSsn = '')"
            data-element="applicationSocialSecurity"
          />
          <Input
            v-else
            :label="getLocal(language, 'enterPhoneNumber.ssn.label')"
            placeholder="SSN/ITIN"
            mask="###-##-####"
            type="text"
            inputmode="numeric"
            v-model:value="ssn"
            ref="ssnInput"
            data-element="applicationSocialSecurity"
          />
        </div>

        <div class="phone__input">
          <Input
            v-if="prevRoute === '/otp'"
            :label="getLocal(language, 'enterPhoneNumber.confirmSsn.label')"
            placeholder="Confirm SSN/ITIN"
            type="text"
            v-model:value="blurredSsn"
            ref="confirmssnInput"
            data-element="applicationSocialSecurity"
          />
          <Input
            v-else
            :label="getLocal(language, 'enterPhoneNumber.confirmSsn.label')"
            :error="ssnError"
            placeholder="Confirm SSN/ITIN"
            mask="###-##-####"
            type="text"
            inputmode="numeric"
            v-model:value="confirmSsn"
            :info="ssnError ? getLocal(language, 'enterPhoneNumber.confirmSsn.error') : ''"
            ref="confirmssnInput"
            data-element="applicationSocialSecurity"
          />
        </div>
      </template>

      <template v-slot:footer>
        <Button large expanded @click="onSubmit" :disabled="!ready || loading" :loading="loading" ref="submit">{{
          getLocal(language, 'enterPhoneNumber.buttonText')
        }}</Button>
      </template>
    </Start>
  </Main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'
import { states } from '../data/states'
import { useRouter } from 'vue-router'

import Button from '../components/common/Button.vue'
import H from '../components/common/H.vue'
import P from '../components/common/P.vue'
import Input from '../components/common/Input.vue'
import Main from '../components/Main.vue'
import Start from '../components/Start.vue'
import Toast from '../components/common/Toast.vue'

export default {
  name: 'EnterPhoneNumber',
  components: {
    Button,
    H,
    P,
    Input,
    Main,
    Start,
    Toast
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    // const version = store.getters["application/getVersion"];

    return {
      store,
      phoneNumber: ref(store.state.user.phoneNumber),
      ssn: ref(store.state.application.ssnITIN),
      confirmSsn: ref(store.state.application.ssnITIN),
      // email: ref(version === `v3` ? "" : store.state.user.email),
      loading: ref(false),
      error: ref(''),
      action: ref(''),
      submit: ref(null),
      ssnInput: ref(null),
      confirmssnInput: ref(null),
      ssnError: ref(false),
      prevRoute: ref(router.options.history.state.back),
      blurredSsn: ref('***-**-****')
    }
  },
  mounted() {
    // hack to prevent persisted ssn (saved to the store) to show
    if (this.ssn) {
      this.prevRoute = '/otp'
    }
  },
  computed: {
    parsedPhoneNumber() {
      return this.phoneNumber?.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').split(' ').join('')
    },
    parsedSSN() {
      return this.ssn.replace('-', '').replace('-', '').split(' ').join('')
    },
    validPhone() {
      return this.phoneNumber?.length === 19
    },
    validSSN() {
      return this.ssn?.length === 11
    },
    securedSSN() {
      return this.validSSN ? this.parsedSSN?.slice(-4) : ''
    },
    ready() {
      const valid = this.validPhone && this.validSSN && this.confirmSsn && this.ssn === this.confirmSsn
      return valid
    },
    language() {
      return this.$store.getters.getLanguage
    }
  },
  watch: {
    parsedPhoneNumber(value) {
      if (value.length === 10 && this.blurredSsn === '') {
        this.ssnInput.setFocus()
      }
    },
    ssn(value) {
      if (value.length === 11) {
        this.confirmssnInput.setFocus()
      }
    },
    confirmSsn(value) {
      if (value.length === 11) {
        this.submit.setFocus()
      }
      if (value.length >= 1) {
        value !== this.ssn ? (this.ssnError = true) : (this.ssnError = false)
      }
    }
  },
  /* 
  there are 3 options:
    otp: send otp
    email: existing number with multiple users (email addresses) - ask for email and call verifyLoginByEmail if valid otp will be sent and user details will be returned
    register: No user match, action is register, ask for email and call verifyEmail (needed to avoid same email exists with different phone) if valid otp will be sent and user details will be returned
  */

  // TO DO:
  // * make it DRY
  methods: {
    getLocal,
    switchLanguage(lang) {
      this.language = lang
      this.store.dispatch('user/updateUserLanguage', {
        language: lang
      })
    },
    tryNext(action) {
      this.action = action

      this.$router.push({
        path: this.$route.meta.next
      })
    },
    async registerOrLogin() {
      if (this.store.getters['user/getPhoneNumber'] !== this.parsedPhoneNumber) {
        this.store.commit('clearCache')
      }

      this.store.commit('application/setSSN', this.ssn)

      const response = await this.store.dispatch('user/registerOrLogin', {
        phoneNumber: this.parsedPhoneNumber,
        ssn: this.ssn.split('-').join(''),
        sms_account_updates: true
      })

      if (response.success) {
        if (response.data?.otpData?.user_id) {
          this.store.commit('user/setUserId', { id: response.data.otpData.user_id })
        }

        if (response.data?.authToken) {
          this.store.commit('user/setAuthToken', { token: response.data.authToken })
        }

        const customerData = response.data?.response?.customerData

        if (customerData) {
          this.store.commit('application/setField', { field: 'aptNumber', value: customerData.apartment_number })
          this.store.commit('application/setField', { field: 'city', value: customerData.city })
          this.store.commit('application/setField', { field: 'idNumber', value: customerData.drivers_license })
          this.store.commit('application/setField', {
            field: 'idState',
            value: states.find(({ value }) => value === customerData.drivers_license_state)
          })
          this.store.commit('application/setField', { field: 'emailAddress', value: customerData.email })
          this.store.commit('application/setField', { field: 'firstName', value: customerData.first_name })
          this.store.commit('application/setField', {
            field: 'idType',
            value: getLocal(this.language, 'form.idType.options').find(({ value }) => value === customerData.id_type)
          })
          this.store.commit('application/setField', { field: 'lastName', value: customerData.last_name })
          this.store.commit('application/setField', {
            field: 'monthlyIncome',
            value: parseFloat(customerData.personal_yearly_income).toFixed(2)
          })
          this.store.commit('application/setField', {
            field: 'state',
            value: states.find(({ value }) => value === customerData.state)
          })
          this.store.commit('application/setField', { field: 'streetAddress', value: customerData.street_address })
          this.store.commit('application/setField', { field: 'zipCode', value: customerData.zipcode })
        }

        this.tryNext(response.data.action)
      } else {
        this.error = response.message
      }

      this.loading = false
    },
    async onSubmit() {
      this.loading = true

      this.store.dispatch('trackEvent', {
        eventName: 'Submit Phone Number Direct',
        payload: {
          last4_SSN: this.securedSSN,
          phone_number: this.phoneNumber,
          source: this.store.state.application.source
        }
      })

      this.registerOrLogin()
    }
  }
}
</script>

<style lang="scss" scoped>
.phone {
  &__info {
    margin-top: 5px;
  }

  &__input {
    margin: 0 auto;
    max-width: 360px;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  &__compliance {
    margin-top: 16px;
    text-align: left;
  }

  &__error {
    margin: 0 auto;
    max-width: 360px;
  }

  &__agreements {
    margin-top: 40px;

    @include media-query(md) {
      margin-top: 60px;
    }
  }
}
</style>
