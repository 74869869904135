import { ENDPOINTS, postWithHeader } from '../../helpers/api'

const contract = {
  namespaced: true,
  state: () => ({
    checkbox: false,
    pdfUrl: '',
    name: ''
  }),
  mutations: {
    setCheckbox(state, { value }) {
      state.checkbox = value
    },
    setPdfUrl(state, { value }) {
      state.pdfUrl = value
    },
    setName(state, { value }) {
      state.name = value
    }
  },
  getters: {
    getCheckbox(state) {
      return state.checkbox
    },
    getPdfUrl(state) {
      return state.pdfUrl
    },
    getName(state) {
      return state.name
    }
  },
  actions: {
    async getContract(context) {
      const response = await postWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.contract, {
        applicationId: context.rootGetters['application/getApplicationId']
      })

      const resp = await response.json()

      if (resp.success) {
        context.commit('setPdfUrl', {
          value: resp.data.contract
        })
      }

      return resp
    },
    async signContract(context, { tokenId }) {
      const response = await postWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.sign, {
        applicationId: context.rootGetters['application/getApplicationId'],
        cardSource: tokenId
      })

      const resp = await response.json()

      return resp
    }
  }
}

export { contract }
