import { Loader } from '@googlemaps/js-api-loader'
import { states } from './states'
import { API_KEY } from './google-maps'
import { moneyInputMaska } from '../helpers/general'
import { isEmail } from '../helpers/validators'

const formDetails = {
  views: [
    {
      name: 'form.step1Name',
      no: '1',
      sections: [
        {
          heading: {
            label: 'form.step1Heading',
            type: 'h4'
          },
          inputs: [
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.firstName.label',
                placeholder: 'John',
                name: 'firstName',
                type: 'text',
                mask: {
                  mask: 'AZ*',
                  tokens: {
                    Z: {
                      pattern: /[a-zA-Z- ]/
                    }
                  }
                },
                test(value) {
                  const notallowed = [' and ', ' with ']

                  if (value.length === 0) {
                    return [true, 'form.required']
                  }

                  if (!notallowed.every((item) => !value.toLowerCase().includes(item))) {
                    return [true, 'form.firstName.individual']
                  }

                  return [false, '']
                }
              }
            },
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.lastName.label',
                placeholder: 'Doe',
                name: 'lastName',
                type: 'text',
                mask: {
                  mask: 'AZ*',
                  tokens: {
                    Z: {
                      pattern: /[a-zA-Z- ]/
                    }
                  }
                },
                test(value) {
                  const notallowed = [' and ', ' with ']

                  if (value.length === 0) {
                    return [true, 'form.required']
                  }

                  if (!notallowed.every((item) => !value.toLowerCase().includes(item))) {
                    return [true, 'form.lastName.individual']
                  }

                  return [false, '']
                }
              }
            },
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.dob.label',
                placeholder: 'MM/DD/YYYY',
                name: 'dateOfBirth',
                mask: '##/##/####',
                type: 'text',
                inputmode: 'numeric',
                test(value) {
                  if (value.length !== 10) {
                    return [true, 'form.required']
                  }

                  const now = new Date()

                  const [month, day, year] = value.split('/')

                  if (parseInt(month) > 12 || parseInt(month) <= 0) {
                    return [true, 'form.dob.incorrectDate']
                  }

                  if (parseInt(day) > 31 || parseInt(day) <= 0) {
                    return [true, 'form.dob.incorrectDate']
                  }

                  if (parseInt(year) > now.getFullYear() || parseInt(year) <= 1900) {
                    return [true, 'form.dob.incorrectDate']
                  }

                  const dob = new Date(year, month - 1, day)

                  // check if date is in the future
                  if (dob > now) {
                    return [true, 'form.dob.incorrectDate']
                  }

                  return [false, '']
                },
                next: 'emailAddress'
              }
            },
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.emailAddress.label',
                placeholder: 'Email Address',
                type: 'email',
                name: 'emailAddress',
                inputmode: 'email',
                test(value) {
                  if (value.length === 0) {
                    return [true, 'form.required']
                  }
                  if (value.length >= 1) {
                    const emailIscorrect = isEmail(value)
                    if (!emailIscorrect) {
                      return [true, 'form.emailAddress.required']
                    }
                  }
                  return [false, '']
                }
              }
            },
            {
              gridItem: {
                cols: 12,
                md: 8
              },
              input: {
                label: 'form.address.label',
                placeholder: 'form.address.placeholder',
                name: 'streetAddress',
                type: 'address',
                mask: {
                  mask: 'Z*',
                  tokens: {
                    Z: {
                      pattern: /[0-9a-zA-Z/.-\s]/
                    }
                  }
                },
                test(value) {
                  if (value.length === 0) {
                    return [true, 'form.required']
                  }

                  return [false, '']
                },
                next: 'idType'
              }
            },
            {
              gridItem: {
                cols: 12,
                sm: 4,
                md: 4
              },
              input: {
                label: 'form.unit.label',
                placeholder: 'form.unit.placeholder',
                name: 'aptNumber',
                type: 'text',
                inputmode: 'numeric',
                mask: {
                  mask: 'Z*',
                  tokens: {
                    Z: {
                      pattern: /[0-9a-zA-Z/.#-]/
                    }
                  }
                },
                test() {
                  return [false, '']
                }
              }
            },
            {
              gridItem: {
                cols: 12,
                sm: 6,
                md: 4
              },
              input: {
                label: 'form.city.label',
                placeholder: 'form.city.placeholder',
                name: 'city',
                type: 'text',
                mask: 'Aa*',
                test(value) {
                  if (value.length === 0) {
                    return [true, 'form.required']
                  }

                  return [false, '']
                }
              }
            },
            {
              gridItem: {
                cols: 12,
                sm: 6,
                md: 4
              },
              input: {
                label: 'form.state.label',
                placeholder: 'form.state.placeholder',
                name: 'state',
                type: 'select',
                options: states,
                test(value) {
                  if (Object.keys(value).length === 0) {
                    return [true, 'form.required']
                  }
                  return [false, '']
                }
              }
            },
            {
              gridItem: {
                cols: 12,
                sm: 6,
                md: 4
              },
              input: {
                label: 'form.zipCode.label',
                placeholder: '11000',
                name: 'zipCode',
                type: 'text',
                inputmode: 'numeric',
                mask: '#####',
                async test(value, { state }) {
                  const loader = new Loader({
                    apiKey: API_KEY,
                    libraries: ['places']
                  })

                  const google = await loader.load()

                  const geocoder = new google.maps.Geocoder()

                  if (value.length === 0) {
                    return [true, 'form.required']
                  }

                  if (state) {
                    try {
                      await geocoder.geocode({
                        componentRestrictions: {
                          postalCode: value,
                          country: 'US',
                          administrativeArea: state.value
                        }
                      })
                    } catch {
                      return [true, 'The zipcode is incorrect']
                    }
                  }

                  if (value.length !== 5) {
                    return [true, 'Enter correct Zip Code']
                  }

                  return [false, '']
                }
              }
            },
            {
              gridItem: {
                cols: 12,
                sm: 6,
                md: 4
              },
              input: {
                label: 'form.idType.label',
                placeholder: 'form.idType.placeholder',
                name: 'idType',
                type: 'select',
                options: 'form.idType.options',
                test(value) {
                  if (Object.keys(value).length === 0) {
                    return [true, 'form.required']
                  }

                  return [false, '']
                },
                next: 'idNumber'
              }
            },
            {
              gridItem: {
                cols: 12,
                sm: 6,
                md: 4
              },
              input: {
                label: 'form.idNumber.label',
                placeholder: '12312312312',
                mask: 'X*',
                name: 'idNumber',
                type: 'text',
                inputmode: 'numeric',
                test(value) {
                  if (value.length === 0) {
                    return [true, 'form.required']
                  }

                  if (value.length > 128) {
                    return [true, 'The value is too long']
                  }

                  return [false, '']
                }
              }
            },
            {
              gridItem: {
                cols: 12,
                sm: 6,
                md: 4
              },
              input: {
                label: 'form.idState.label',
                placeholder: 'form.idState.placeholder',
                name: 'idState',
                type: 'select',
                options: states,
                disabled: ({ idType }) => idType.value === 'US Passport',
                test(value) {
                  if (Object.keys(value).length === 0) {
                    return [true, 'form.required']
                  }

                  return [false, '']
                }
              }
            }
          ]
        }
      ]
    },
    {
      name: 'form.step2Name',
      no: '2',
      sections: [
        {
          heading: {
            label: 'form.step2Heading',
            type: 'h4'
          },
          inputs: []
        },
        {
          heading: {
            label: 'form.step2SubHeadingOne',
            type: 'h5'
          },
          inputs: [
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.monthlyIncome.label',
                name: 'monthlyIncome',
                mask: moneyInputMaska,
                placeholder: '$ 0',
                type: 'text',
                msg: 'form.monthlyIncome.msg',
                inputmode: 'numeric',
                test(value) {
                  if (value.length < 1) {
                    return [true, 'form.monthlyIncome.error']
                  }

                  if (value.length !== 7) {
                    return [true, 'form.monthlyIncome.required']
                  }

                  return [false, '']
                },
                next: 'paycheckFrequency'
              }
            },
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.paycheckFrequency.label',
                placeholder: 'form.paycheckFrequency.placeholder',
                name: 'paycheckFrequency',
                type: 'select',
                options: 'form.paycheckFrequency.options',
                test(value) {
                  return Object.keys(value).length !== 0 ? [false, ''] : [true, 'form.required']
                },
                next: 'lastPayDate'
              }
            },
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.lastPayDate.label',
                name: 'lastPayDate',
                placeholder: 'MM/DD/YYYY',
                upperLimit: () => new Date(),
                type: 'date',
                test(value) {
                  if (Object.keys(value).length === 0) {
                    return [true, 'form.required']
                  }

                  return [false, '']
                },
                next: 'nextPayDate'
              }
            },
            {
              gridItem: {
                cols: 12,
                placeholder: 'MM/DD/YYYY',
                md: 6
              },
              input: {
                label: 'form.nextPayDate.label',
                name: 'nextPayDate',
                placeholder: 'MM/DD/YYYY',
                disabled: ({ lastPayDate, paycheckFrequency }) => !lastPayDate || !paycheckFrequency.value,
                lowerLimit: ({ lastPayDate, paycheckFrequency }) => {
                  let date = new Date(lastPayDate)

                  switch (paycheckFrequency.value) {
                    case 'weekly':
                      date.setDate(date.getDate() + 4)
                      break
                    case 'bi-weekly':
                      date.setDate(date.getDate() + 12)
                      break
                    case 'bi-monthly':
                      date.setDate(date.getDate() + 10)
                      break
                    case 'monthly':
                      date.setMonth(date.getMonth() + 1)
                      break
                    default:
                      date = undefined
                  }

                  return date
                },
                upperLimit: ({ lastPayDate, paycheckFrequency }) => {
                  let date = new Date(lastPayDate)

                  switch (paycheckFrequency.value) {
                    case 'weekly':
                      date.setDate(date.getDate() + 10)
                      break
                    case 'bi-weekly':
                      date.setDate(date.getDate() + 18)
                      break
                    case 'bi-monthly':
                      date.setDate(date.getDate() + 21)
                      break
                    case 'monthly':
                      date.setMonth(date.getMonth() + 1)
                      date.setDate(date.getDate() + 3)
                      break
                    default:
                      date = undefined
                  }

                  return date
                },
                type: 'date',
                test(value) {
                  if (Object.keys(value).length === 0) {
                    return [true, 'form.required']
                  }

                  return [false, '']
                },
                next: 'routingNumber'
              }
            }
          ]
        },
        {
          heading: {
            label: 'form.step2SubHeadingTwo',
            type: 'h5'
          },
          inputs: [
            {
              gridItem: {
                cols: 12
              },
              input: {
                type: 'plaid',
                label: 'form.plaid.label',
                success: 'form.plaid.success',
                name: 'plaid',
                info: 'form.plaid.info',
                clear: ['routingNumber', 'accountNumber']
              }
            },
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.routingNumber.label',
                placeholder: 'form.routingNumber.placeholder',
                name: 'routingNumber',
                mask: '#########',
                type: 'text',
                inputmode: 'numeric',
                disabled: ({ plaid }) => !!plaid && !!plaid.accountId && !!plaid.bankSource,
                test(value) {
                  if (value.length !== 9) {
                    return [true, 'form.routingNumber.isInvalid']
                  }

                  if ([`031101279`, `062201601`, `103100195`, `114924742`].includes(value)) {
                    return [true, 'form.routingNumber.isNotAllowed']
                  }

                  const prefix = parseInt(value.slice(0, 2))

                  if (!((prefix >= 1 && prefix <= 12) || (prefix >= 21 && prefix <= 32))) {
                    return [true, 'form.routingNumber.isInvalid']
                  }

                  return [false, '']
                },
                next: 'accountNumber'
              }
            },
            {
              gridItem: {
                cols: 12,
                md: 6
              },
              input: {
                label: 'form.accountNumber.label',
                placeholder: 'form.accountNumber.placeholder',
                'data-element': 'bankAccountNumber',
                name: 'accountNumber',
                mask: '#################',
                type: 'text',
                inputmode: 'numeric',
                disabled: ({ plaid }) => !!plaid && !!plaid.accountId && !!plaid.bankSource,
                test(value) {
                  if (value.length < 4) {
                    return [true, 'form.accountNumber.isIncomplete']
                  }

                  if (value.length > 17) {
                    return [true, '']
                  }

                  return [false, '']
                }
              }
            }
          ]
        }
      ]
    },
    {
      name: 'form.step3Name',
      no: '3',
      sections: [
        {
          heading: {
            label: 'form.step1Name',
            type: 'h4',
            editTo: '/about-you'
          },
          grid: 'tightVertically',
          inputs: [
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'name',
                label: 'form.name',
                getter: 'application/getName'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'email',
                label: 'form.email',
                getter: 'application/getEmail'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'dateOfBirth',
                label: 'form.dob.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'ssnITIN',
                label: 'form.ssn.label',
                getter: 'application/getMaskedSsnITIN'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'streetAddress',
                label: 'form.address.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'aptNumber',
                label: 'form.unit.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'city',
                label: 'form.city.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'state',
                label: 'form.state.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'zipCode',
                label: 'form.zipCode.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'idType',
                label: 'form.idType.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'idState',
                label: 'form.idState.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'idNumber',
                label: 'form.idNumber.label'
              }
            }
          ]
        },
        {
          heading: {
            label: 'form.step2Name',
            type: 'h4',
            editTo: '/income-details'
          },
          grid: 'tightVertically',
          inputs: [
            {
              gridItem: {
                cols: 6
              },
              input: {
                type: 'preview',
                name: 'monthlyIncome',
                label: 'form.monthlyIncome.label',
                nowrap: true
              }
            },
            {
              gridItem: {
                cols: 6
              },
              input: {
                type: 'preview',
                name: 'paycheckFrequency',
                label: 'form.paycheckFrequency.label'
              }
            },
            {
              gridItem: {
                cols: 6
              },
              input: {
                type: 'preview',
                name: 'lastPayDate',
                label: 'form.lastPayDate.label'
              }
            },
            {
              gridItem: {
                cols: 6
              },
              input: {
                type: 'preview',
                name: 'nextPayDate',
                label: 'form.nextPayDate.label'
              }
            },
            {
              gridItem: {
                cols: 6
              },
              input: {
                type: 'preview',
                name: 'routingNumber',
                label: 'form.routingNumber.label'
              }
            },
            {
              gridItem: {
                cols: 6
              },
              input: {
                type: 'preview',
                name: 'accountNumber',
                label: 'form.accountNumber.label',
                getter: 'application/getMaskedAccountNumber'
              }
            }
          ]
        },
        {
          heading: {
            label: 'form.merchantDetails.label',
            type: 'h4'
          },
          grid: 'tightVertically',
          inputs: [
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'merchantName',
                label: 'form.merchantDetails.merchant.label'
              }
            },
            {
              gridItem: {
                cols: 6,
                md: 4
              },
              input: {
                type: 'preview',
                name: 'storeName',
                label: 'form.merchantDetails.store.label'
              }
            }
          ]
        }
      ]
    }
  ]
}

const defaults = {
  text: '',
  select: {},
  date: '',
  email: '',
  address: '',
  plaid: {
    bankSource: '',
    accountId: ''
  }
}

const { views } = formDetails

// sectionsFromViews :: [view] -> [section]
const sections = (formViews) => formViews.reduce((acc, view) => [...acc, ...view.sections], [])

// combined inputs flattened
// inputsFromSections :: [section] -> [input]
const inputs = (items) =>
  items
    .reduce((acc, { inputs: inputsArray }) => [...acc, ...inputsArray], [])
    .map(({ input }) => input)
    .reduce((acc, input) => (input.type === 'combined' ? [...acc, ...input.inputs] : [...acc, input]), [])

// inputsReduce :: (String, Fn) -> ([input] -> Object<{ [input.name]: any }>)
const inputsData = (field, value) => (items) =>
  items.reduce(
    (acc, item) => ({
      ...acc,
      [item[field]]: value(item)
    }),
    {}
  )

// data :: [input] -> Object<{ [input.name]: String }>
const data = inputsData('name', (cur) => defaults[cur.type])

// validators :: [input] -> Object<{ [input.name]: Fn }>
const validators = inputsData('name', (cur) => cur.test)

// errors :: [input] -> Object<{ [input.name]: [false, ""] }>
const errors = inputsData('name', () => [false, ''])

export { views, sections, inputs, data, validators, errors }

export default formDetails
