<template>
  <p
    class="paragraph"
    :class="{
      'paragraph--body1': body1,
      'paragraph--body2': body2,
      'paragraph--small1': small1,
      'paragraph--small2': small2,
      'paragraph--small3': small3,
      'paragraph--tiny1': tiny1,
      'paragraph--tiny2': tiny2,
      'paragraph--regular': regular,
      'paragraph--color-inherit': colorInherit
    }"
  >
    <slot />
  </p>
</template>

<script>
export default {
  name: 'P',
  props: {
    body1: Boolean,
    body2: Boolean,
    small1: Boolean,
    small2: Boolean,
    small3: Boolean,
    tiny1: Boolean,
    tiny2: Boolean,
    regular: Boolean,
    colorInherit: Boolean
  }
}
</script>

<style lang="scss">
.paragraph {
  margin: 0;
  color: get-color(text-2);
  font-weight: 500;

  &--body1 {
    font-size: 20px;
    line-height: 35px;
  }

  &--body2 {
    font-size: 18px;
    line-height: 32px;
  }

  &--small1 {
    font-size: 16px;
    line-height: 28px;
  }

  &--small2 {
    font-size: 14px;
    line-height: 24px;
  }

  &--tiny1 {
    font-size: 12px;
    line-height: 20px;
  }

  &--tiny2 {
    font-size: 10px;
    line-height: 18px;
    font-weight: 600;
  }

  &--regular {
    font-weight: 400;
  }

  &--color-inherit {
    color: inherit;
  }
}
</style>
