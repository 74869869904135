import { createStore } from 'vuex'

import { states } from '../data/states'

import { urlVariables } from '../data/url-variables'
import { user } from './modules/user'
import { application } from './modules/application'
import { contract } from './modules/contract'
import { cart } from './modules/cart'
import { pricing } from './modules/pricing'
import { localState, merchantDetails } from './plugins'

import router from '../router'

const store = createStore({
  state: () => ({
    language: 'English', // default user language is English
    iframe: 'false'
  }),
  modules: {
    user,
    application,
    cart,
    pricing,
    contract
  },
  getters: {
    getField:
      (state) =>
      ({ module, field }) => {
        if (module) {
          return state[module][field]
        }
        return state[field]
      },
    getLanguage: (state) => state.language,
    getIframe: (state) => state.iframe === 'true'
  },
  mutations: {
    setField(state, { module, field, value }) {
      if (module) {
        state[module][field] = value
      } else {
        state[field] = value
      }
    },
    setLanguage(state, val) {
      state.language = val
    },
    clearCache() {},
    resetState() {}
  },
  actions: {
    setUrlVariables(context, urlParams) {
      urlVariables.map(([field, storeField, module]) => {
        const value = urlParams.get(field)

        if (value !== null) {
          context.commit('setField', {
            module,
            field: storeField,
            value: field === 'v' ? `v${value.replace(/[^0-9]/g, '')}` : value
          })
        }
      })
    },
    async redirectOnStatus(context, callback) {
      // prevent redirect for card details
      const { authorization } = context.getters['user/getAuth']

      if (context.getters['application/getCardAuthToken'] && authorization) {
        router.push({ path: '/card-details' }).then(callback)
        return
      }

      const { success, data } = await context.dispatch('application/applicationStatus')

      const isLoggedIn = !!authorization

      let path = isLoggedIn ? '/about-you' : '/welcome'

      if (success) {
        const applications = data?.applications.filter(({ online_version }) => !!online_version)

        if (applications.length) {
          const app = applications[0]

          context.commit('application/setApplicationId', app.id)
          context.commit('application/setAmount', app.approval_amount)
          context.commit('cart/setAssetPrice', app.asset_price)
          context.commit('cart/setShipment', app.shipping_cost)
          context.commit('application/setField', {
            field: 'state',
            value: states.find(({ value }) => value === app.state)
          })

          if (app.activecard && app.activecard.id) {
            context.commit('application/setActiveCardId', app.activecard.id)
          }

          switch (app.application_status) {
            case 'Enter Merchandise Details':
              path = '/invoice-creator'
              break
            case 'Sign and Upload Contract':
              path = '/lease-estimator'
              break
            case 'Swipe Card':
              path = '/card-details'
              break
            case 'Decision Pending':
              path = '/manual-review'
              break
            case 'Denied':
              path = '/declined'
              break
            case 'Enter Merchandise':
              path = '/approval'
              break
            case 'Completed':
              path = '/about-you'
              break
            default:
              path = '/about-you'
          }
        }
      }

      router.push({ path }).then(callback)
    },
    logout(context) {
      const params = urlVariables
        .map(([field, storeField, module]) => {
          const value = context.getters.getField({ module, field: storeField })

          return value ? `${field}=${value}` : ``
        })
        .filter((item) => item)

      context.commit('clearCache')

      window.location.href = `/?${params.join('&')}`
    },
    trackEvent(context, { eventName, payload }) {
      window.FS.event(eventName, payload)
    }
  },
  plugins: [localState, merchantDetails]
})

export { store }
