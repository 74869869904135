const lsKey = `store@${process.env.VUE_APP_VERSION}`
const lsUrl = 'store@url'

const localState = async (store) => {
  const urlVars = window.location.search

  if (urlVars) {
    localStorage.setItem(lsUrl, urlVars)
  }

  try {
    const localStateJson = localStorage.getItem(lsKey)

    if (localStateJson) {
      store.replaceState({
        ...store.state,
        ...JSON.parse(localStateJson)
      })
    }
  } catch {
    console.warn('Kafene App: Local storage cannot be read due to browser settings')
    return
  }

  await store.dispatch('setUrlVariables', new URLSearchParams(localStorage.getItem(lsUrl)))

  const initialStateCopy = JSON.parse(JSON.stringify(store.state))

  const resetState = () => {
    store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
  }

  // clear localStorage on cache clear and save in cache on any other change
  store.subscribe(({ type }, state) => {
    if (type === 'clearCache') {
      localStorage.removeItem(lsKey, JSON)
      resetState()
    } else {
      // clear single time use card auth token before saving
      localStorage.setItem(
        lsKey,
        JSON.stringify({ ...state, application: { ...state.application, cardAuthToken: '' } })
      )
    }
  })
}

const merchantDetails = (store) => {
  const condition = (state) => state.user.authToken && state.application.storeId && !state.application.storeName

  const requestMerchantDetails = (state) => {
    if (condition(state)) {
      store.dispatch('application/merchantDetails')
    }
  }

  requestMerchantDetails(store.state)

  store.subscribe(({ type, payload }, state) => {
    if (type === 'user/setAuthToken' || (!!payload && payload.field === 'storeId')) {
      requestMerchantDetails(state)
    }
  })
}

export { localState, merchantDetails }
