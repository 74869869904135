// TO DO:
// Refactor to more composable structure

const BASE_URL = process.env.VUE_APP_API_BASE_URL || `https://devapi.kafene.com/v2/`

const HEADERS = {
  'Content-Type': 'application/json'
}

const ENDPOINTS = {
  // registerOrLogin: `auth/registerOrLogin`,
  registerOrLogin: `auth/login-v2`,
  verifyLoginByEmail: `auth/verifyLoginByEmail`,
  verifyEmail: `auth/verifyEmail`,
  login: `auth/login-v2/verify-otp`,
  resendOtp: `auth/login-v2/resend-otp`,
  createApplication: `application/create`,
  leaseSummary: `application/lease-summary`,
  home: `direct/home`,
  itemsCreate: `item-info/create`,
  pricing: `pricing/get`,
  store: `store/get`,
  sign: `application/sign-contract`,
  setPricing: `application/set-pricing`,
  contract: `application/contract`,
  createCard: `application/create-card`,
  unlockCard: `application/unlock-card`,
  cardAccessLink: `application/card-access-details`,
  cardDetails: `application/card-details`
}

// const endpoint = action => ENDPOINTS[action]

// fetch :: Object, Object -> (String, Object -> Promise)
const createFetch = (headers, options) => (url, data) => {
  return fetch(`${BASE_URL}${url}`, {
    ...options,
    headers: {
      ...headers
    },
    body: JSON.stringify(data)
  })
}

// const json = response => response.json()

const post = createFetch(HEADERS, {
  method: `POST`
})

const postWithHeader = (headers) =>
  createFetch(
    { ...HEADERS, ...headers },
    {
      method: `POST`
    }
  )

const get = createFetch(HEADERS, {
  method: `GET`
})

const getWithHeader = (headers) =>
  createFetch(
    { ...HEADERS, ...headers },
    {
      method: `GET`
    }
  )

const put = createFetch(HEADERS, {
  method: `PUT`
})

const putWithHeader = (headers) =>
  createFetch(
    { ...HEADERS, ...headers },
    {
      method: `PUT`
    }
  )

export { ENDPOINTS, post, postWithHeader, getWithHeader, get, put, putWithHeader }
