<template>
  <div class="media">
    <div class="media__image">
      <slot />
    </div>

    <div class="media__main">
      <H is="h5" h6 class="media__heading">{{ heading }}</H>

      <div class="media__content">
        <P class="media__paragraph">{{ paragraph }}</P>
      </div>
    </div>
  </div>
</template>

<script>
import H from './H.vue'
import P from './P.vue'

export default {
  props: {
    heading: String,
    paragraph: String
  },
  components: {
    H,
    P
  }
}
</script>

<style lang="scss" scoped>
.media {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__image {
    flex: 0 0 42px;
    padding-top: 8px;

    > img,
    svg {
      max-width: 100%;
    }
  }

  &__main {
    flex: 1 1 auto;
    margin-left: 16px;

    > * + * {
      margin-top: 4px;
    }
  }

  &__heading {
    color: #000;
  }

  // move to P
  &__paragraph {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}
</style>
