<template>
  <div ref="app" class="app">
    <div v-if="iframe" class="app__close">
      <router-link v-if="iframe" to="/close" class="app__close-link">
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="5.637" y="16.95" width="16" height="2" rx="1" transform="rotate(-45 5.637 16.95)" fill="#4E5D78" />
          <rect x="7.051" y="5.636" width="16" height="2" rx="1" transform="rotate(45 7.05 5.636)" fill="#4E5D78" />
        </svg>
      </router-link>
    </div>

    <main ref="main" class="app__main">
      <div class="list-router-tmp" v-if="showTempNav">
        <p><router-link to="/">1</router-link></p>
        <p><router-link to="/otp">2</router-link></p>
        <p><router-link to="/about-you">3:1</router-link></p>
        <p><router-link to="/income-details">3:2</router-link></p>
        <p><router-link to="/review">3:3</router-link></p>
        <p><router-link to="/terms">3:4</router-link></p>
        <p><router-link to="/approval">4:a</router-link></p>
        <p><router-link to="/declined">4:d</router-link></p>
        <p><router-link to="/manual-review">4:m</router-link></p>

        <p><router-link to="/invoice-creator">5</router-link></p>
        <p><router-link to="/lease-estimator">6</router-link></p>
        <p><router-link to="/payment-details">7</router-link></p>
        <p><router-link to="/card-details">8</router-link></p>
      </div>

      <div class="app__header">
        <A href="https://kafene.com/" :blank="iframe" class="app__logo">
          <Logo />
        </A>

        <div class="switch__language">
          <Button large link icon @click="changeLanguage" v-if="showTempNav">
            <template v-slot:icon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"
                  fill="#22C58B"
                />
              </svg>
            </template>
            <span>{{ getLocal(language, 'languageText') }}</span>
          </Button>
        </div>

        <div class="app__logout" :class="{ 'app__logout--spaced': iframe }">
          <Button v-if="!$route.meta.hideLogout" link inline @click="logout">Logout</Button>
        </div>
      </div>

      <div class="app__content" v-if="ready">
        <router-view :key="$route.fullPath" />
      </div>

      <div class="app__loader" v-else>
        <Spinner large dark />
      </div>
    </main>

    <aside class="app__aside">
      <LeaseInfo v-if="$route.meta.isLeaseInfo" />

      <Features v-else />

      <p class="app__version">v{{ version }}</p>
    </aside>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from './data/locales'

import A from './components/common/A.vue'
import Button from './components/common/Button.vue'
import Logo from './components/common/Logo.vue'
import Features from './components/Features.vue'
import LeaseInfo from './components/LeaseInfo.vue'
import Spinner from './components/common/Spinner.vue'

export default {
  name: 'App',
  setup() {
    const store = useStore()

    return {
      app: ref(null),
      main: ref(null),
      store,
      version: process.env.VUE_APP_VERSION,
      showTempNav: location.hostname !== 'online.kafene.com',
      ready: ref(false)
    }
  },
  computed: {
    language() {
      return this.$store.getters.getLanguage
    },
    iframe() {
      return this.$store.getters.getIframe
    }
  },
  components: {
    A,
    Button,
    Logo,
    Features,
    LeaseInfo,
    Spinner
  },
  methods: {
    getLocal,
    logout() {
      this.store.dispatch('logout')
    },
    changeLanguage() {
      this.store.commit('setLanguage', this.language === 'English' ? 'Spanish' : 'English')
    }
  },
  async mounted() {
    this.$iframeBus.post('mounted')

    this.$iframeBus.on('setCart', (payload) => {
      this.store.commit('cart/setShopifyItems', { items: payload.items })
    })

    this.$iframeBus.on('setShipment', (payload) => {
      this.iframe = true

      if (parseFloat(payload.amount) !== 0) {
        this.store.commit('cart/setShipment', payload.amount)
      }
    })

    await this.store.dispatch('redirectOnStatus', () => (this.ready = true))
  },
  watch: {
    $route() {
      this.app.scrollTo({ top: 0 })
      this.main.scrollTo({ top: 0 })
    }
  }
}
</script>

<style lang="scss">
body {
  font-family: 'Inter', sans-serif;
  color: #8a94a6;
  margin: 0;
  padding: 0;
  display: flex;
}

p {
  margin: 0;
  color: #323b4b;
}

a {
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.list-router-tmp {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: 0;

  > * {
    flex: 0 0 auto;

    + * {
      margin-left: 8px;
    }
  }
}

.app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  @include media-query(sm) {
    overflow: hidden;
    flex-direction: row;
  }

  &__main,
  &__aside {
    position: relative;

    @include media-query(sm) {
      overflow: auto;
    }
  }

  &__aside {
    overflow: hidden;
    position: relative;
  }

  &__close {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;
  }

  &__close-link {
    display: block;
  }

  &__main {
    flex: 0 0 auto;
    padding: 20px;
    padding-bottom: 40px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    @include media-query(sm) {
      flex: 1 1 auto;
      padding: 40px;
    }
  }

  &__aside {
    background-color: #f8f9fb;
    flex: 0 0 auto;

    @include media-query(sm) {
      flex: 0 0 300px;
      min-width: 300px;
    }

    @include media-query(md) {
      flex: 0 0 445px;
      min-width: 445px;
    }
  }

  &__header {
    flex: 0 0 auto;
    // TMP
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__content {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }

  &__logo {
    display: block;
    max-width: 100px;
    width: 100%;
  }

  &__content {
    margin-top: 24px;
  }

  &__version {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__logout {
    // for close icon on v3 iframe
    &--spaced {
      padding-right: 64px;

      @include media-query(sm) {
        padding-right: 0;
      }
    }
  }

  &__loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.switch__language {
  .button__icon--start {
    margin-right: 5px;
  }
}
</style>
