const pricing = {
  namespaced: true,
  state: () => ({
    initialPayment: 0,
    complianceSmS: false
  }),
  mutations: {
    setInitialPayment(state, { initialPayment }) {
      state.initialPayment = initialPayment
    },
    setComplianceSmS(state, { complianceSmS }) {
      state.complianceSmS = complianceSmS
    }
  },
  getters: {
    getInitialPayment(state) {
      return state.initialPayment
    },
    getComplianceSmS(state) {
      return state.complianceSmS
    }
  }
}

export { pricing }
