<template>
  <div
    class="main"
    :class="{
      'main--center': center
    }"
  >
    <div
      class="main__content"
      :class="{
        'main__content--flex': contentFlex
      }"
    >
      <slot />
    </div>

    <div v-if="$slots.footer" class="main__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    center: Boolean,
    contentFlex: Boolean
  }
}
</script>

<style lang="scss" scoped>
.main {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  color: #8a94a6;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;

  &__content {
    flex: 1 0 auto;

    &--flex {
      display: flex;
      flex-direction: column;
    }
  }

  &__footer {
    flex: 0 0 auto;
    margin-top: 80px;
  }

  &--center {
    text-align: center;
  }
}
</style>
