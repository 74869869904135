import { createRouter, createMemoryHistory } from 'vue-router'

import EnterPhoneNumber from '../views/EnterPhoneNumber.vue'

const routes = [
  {
    path: '/',
    redirect: '/welcome'
  },
  {
    path: '/welcome',
    name: 'EnterPhoneNumber',
    component: EnterPhoneNumber,
    meta: {
      next: '/otp',
      hideLogout: true
    }
  },
  {
    path: '/otp',
    name: 'CodeVerification',
    // route level code-splitting
    // this generates a separate chunk ([name].[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "codeVerification" */ '../views/CodeVerification.vue'),
    meta: {
      prev: '/',
      next: '/about-you'
    }
  },
  {
    path: '/about-you',
    name: 'AboutYou',
    component: () => import(/* webpackChunkName: "detailsForm" */ '../views/DetailsForm.vue'),
    meta: {
      next: '/income-details',
      step: '1'
    }
  },
  {
    path: '/income-details',
    name: 'IncomeDetails',
    component: () => import(/* webpackChunkName: "detailsForm" */ '../views/DetailsForm.vue'),
    meta: {
      prev: '/about-you',
      next: '/review',
      step: '2'
    }
  },
  {
    path: '/review',
    name: 'Review',
    component: () => import(/* webpackChunkName: "detailsForm" */ '../views/DetailsForm.vue'),
    meta: {
      prev: '/income-details',
      next: '/terms',
      step: '3'
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "codeVerification" */ '../views/Terms.vue'),
    meta: {
      prev: '/review',
      next: (status) => {
        switch (status) {
          case 'approved':
            return '/approval'
          case 'denied':
          case 'cancelled':
            return '/declined'
          case 'manual':
            return '/manual-review'
          default:
            return 0
        }
      }
    }
  },
  {
    path: '/terms/:slug',
    name: 'TermsPreview',
    component: () => import(/* webpackChunkName: "termsPreview" */ '../views/TermsPreview.vue')
  },
  {
    path: '/approval',
    name: 'Approved',
    component: () => import(/* webpackChunkName: "approved" */ '../views/Approved.vue'),
    meta: {
      next: '/invoice-creator'
    }
  },
  {
    path: '/declined',
    name: 'Declined',
    component: () => import(/* webpackChunkName: "declined" */ '../views/Declined.vue')
  },
  {
    path: '/manual-review',
    name: 'MoreInfo',
    component: () => import(/* webpackChunkName: "moreInfo" */ '../views/MoreInfo.vue')
  },
  {
    path: '/invoice-creator',
    name: 'InvoiceCreator',
    component: () => import(/* webpackChunkName: "invoiceCreator" */ '../views/InvoiceCreator.vue'),
    meta: {
      next: '/lease-estimator'
    }
  },
  {
    path: '/lease-estimator',
    name: 'LeaseEstimator',
    component: () => import(/* webpackChunkName: "invoiceCreator" */ '../views/LeaseEstimator.vue'),
    meta: {
      prev: '/invoice-creator',
      next: '/payment-details'
    }
  },
  {
    path: '/payment-details',
    name: 'PaymentDetails',
    component: () => import(/* webpackChunkName: "terms" */ '../views/PaymentDetails.vue'),
    meta: {
      prev: '/lease-estimator',
      next: '/card-details'
    }
  },
  {
    path: '/card-details',
    name: 'CardDetails',
    component: () => import(/* webpackChunkName: "cardDetails" */ '../views/CardDetails.vue')
  },
  {
    path: '/close',
    name: 'Close',
    component: () => import(/* webpackChunkName: "declined" */ '../views/Close.vue'),
    meta: {
      hideLogout: true
    }
  }
]

const router = createRouter({
  history: createMemoryHistory(),
  routes
})

export default router
