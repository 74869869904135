<template>
  <div class="lease-info" :class="{ 'lease-info--details': isDetails }">
    <div class="lease-info__wrapper">
      <div class="lease-info__section lease-info__section--overview">
        <LeaseOverview @footerButtonClick="isDetails = !isDetails" />
      </div>

      <div class="lease-info__section lease-info__section--details">
        <LeaseDetails @footerButtonClick="isDetails = !isDetails" />
      </div>
    </div>
  </div>
</template>

<script>
import LeaseOverview from './LeaseOverview.vue'
import LeaseDetails from './LeaseDetails.vue'

export default {
  components: {
    LeaseOverview,
    LeaseDetails
  },
  data() {
    return {
      isDetails: false
    }
  }
}
</script>

<style lang="scss">
.lease-info {
  $this: &;

  position: relative;
  overflow: hidden;

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    transition: ease 300ms all;
  }

  &__section {
    flex: 0 0 100%;
    min-width: 100%;
  }

  &--details {
    #{$this}__wrapper {
      transform: translateX(-100%);
    }
  }
}
</style>
