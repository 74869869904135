<template>
  <div
    class="spinner"
    :class="{
      'spinner--large': large,
      'spinner--dark': dark
    }"
  ></div>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  position: relative;
  margin: 0 auto;

  border-radius: 100%;
  width: 16px;
  height: 16px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;

    border: 3px solid transparent;
    border-radius: 100%;
    width: 100%;
    height: 100%;
  }

  &::before {
    z-index: 100;
    animation: spin 1s infinite;
    border-color: white;
    border-bottom-color: transparent;
  }

  &--large {
    width: 40px;
    height: 40px;
  }

  &--dark {
    &::before,
    &::after {
      top: -4px;
      left: -5px;

      border: 4px solid transparent;
    }

    &::before {
      border-color: get-color(bg-1);
      border-bottom-color: transparent;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
