<template>
  <div class="overview">
    <div class="overview__content">
      <div class="overview__section">
        <svg width="29" height="32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.851 5.354v21.372c0 .7-.277 1.371-.771 1.866a2.629 2.629 0 01-1.861.773H2.632a2.63 2.63 0 01-1.861-.773A2.641 2.641 0 010 26.726V8a2.64 2.64 0 011.625-2.436c.32-.132.662-.2 1.007-.2l9.219-.01zM20.526 20.503a2.669 2.669 0 00-.445-.378 2.601 2.601 0 00-1.465-.449 2.63 2.63 0 00-1.861.773 2.641 2.641 0 00-.771 1.866v7.05c0 .699.278 1.37.772 1.863a2.63 2.63 0 001.86.772h7.002a2.628 2.628 0 002.322-1.4 2.644 2.644 0 00-.416-3.056l-6.998-7.041z"
            fill="#00E091"
          />
          <path
            opacity=".45"
            d="M2.632 29.364l9.208-9.228V5.354H2.632A2.625 2.625 0 00.2 6.98c-.132.32-.2.663-.2 1.009v18.726c0 .7.277 1.371.771 1.866a2.63 2.63 0 001.861.773v.01z"
            fill="url(#paint0_radial)"
          />
          <path
            d="M9.227 2.63V16.42a2.64 2.64 0 001.58 2.416 2.624 2.624 0 002.841-.488L27.626 4.337l.044-.05c.39-.46.597-1.048.585-1.652A2.637 2.637 0 0026.632.2c-.319-.132-.661-.2-1.006-.2H11.859c-.697 0-1.365.276-1.859.769a2.638 2.638 0 00-.773 1.86z"
            fill="#00E091"
          />
          <defs>
            <radialGradient
              id="paint0_radial"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="rotate(146.411 8.305 8.682) scale(26.0472 22.2936)"
            >
              <stop offset=".43" stop-color="#06A27E" />
              <stop offset=".58" stop-color="#28BE95" stop-opacity=".65" />
              <stop offset=".71" stop-color="#42D5A8" stop-opacity=".37" />
              <stop offset=".83" stop-color="#55E5B5" stop-opacity=".17" />
              <stop offset=".93" stop-color="#61EEBD" stop-opacity=".04" />
              <stop offset="1" stop-color="#65F2C0" stop-opacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>

      <div class="overview__section overview__section--flex">
        <p class="overview__label">Your Cart</p>

        <p class="overview__value">$249.99</p>
      </div>

      <div class="overview__section">
        <div class="table">
          <div class="table__item">
            <p class="table__label">Initial cart</p>

            <p class="table__value">$249.99</p>
          </div>

          <div class="table__item">
            <p class="table__label table__label--strong">Revised cart</p>

            <p class="table__value table__value--strong">$249.99</p>
          </div>

          <div class="table__footer">
            <div class="table__item">
              <p class="table__label table__label--strong">Total due today</p>

              <p class="table__value table__value--strong">$83.11</p>
            </div>

            <div class="table__item">
              <p class="table__label table__label--strong">Recurring amount</p>

              <p class="table__value table__value--strong">$20.10</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overview__footer">
      <Button large link icon reversed @click="$emit('footerButtonClick')">
        <template v-slot:icon>
          <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.75 9.5L6 5.25 1.75 1"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </template>

        <span>View more details</span>
      </Button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'

import Button from './common/Button.vue'

export default {
  name: 'LeaseOverview',
  components: {
    Button
  },
  setup() {
    return {
      store: useStore()
    }
  }
}
</script>

<style lang="scss" scoped>
.overview {
  padding: 40px 20px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;

  @include media-query(sm) {
    padding: 40px;
    padding-top: 80px;
  }

  &__content {
    flex: 1 1 auto;

    > * + * {
      margin-top: 24px;
    }
  }

  &__section {
    &--flex {
      display: flex;
      flex-direction: column;

      @include media-query(md) {
        align-items: center;
        flex-direction: row;
      }
    }
  }

  &__label {
    flex: 1 1 auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #323b4b;
  }

  &__value {
    margin-top: 8px;
    flex: 0 0 auto;
    font-weight: 600;
    font-size: 32px;
    line-height: 29px;
    color: #22c58b;

    @include media-query(md) {
      margin-top: 0;
      margin-left: 8px;
    }
  }

  &__footer {
    text-align: center;
    flex: 0 0 auto;
    margin-top: 40px;
  }
}

.table {
  $this: &;

  color: #323b4b;

  &__header {
    padding: 8px 0;
    border-bottom: 2px solid rgba(176, 183, 195, 0.3);
    margin-bottom: 8px;
  }

  &__heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    color: #323b4b;
  }

  &__item {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__label {
    flex: 1 1 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;

    &--narrow {
      flex: 0 1 181px;
    }

    &--strong {
      font-weight: 800;
    }
  }

  &__value {
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    margin-left: 8px;

    &--strong {
      font-weight: 800;
    }
  }

  &__footer {
    border-top: 2px solid rgba(176, 183, 195, 0.3);
    margin-top: 8px;
    padding-top: 8px;
  }
}
</style>
