/* eslint-disable vue/max-len */

import get from 'lodash/get'

const en = {
  languageText: 'Versión en español',
  enterPhoneNumber: {
    header: 'Enter Your Phone Number',
    info: `We’ll text you a verification code to start or resume your
    application (standard SMS charges may apply).`,
    phoneNo: {
      label: 'Mobile Number'
    },
    ssn: {
      label: 'SSN/ITIN'
    },
    confirmSsn: {
      label: 'Confirm SSN/ITIN',
      error: 'SSN/ITIN does not match'
    },
    email: {
      label: 'Email',
      info: 'We need your email address to authenticate you'
    },
    buttonText: 'Submit'
  },
  codeVerification: {
    header: 'Verify Your Phone Number',
    info: 'Enter the 6-digit verification code sent to:',
    buttonText: 'Verify',
    otpText: 'Re-send code',
    cta: 'Change number'
  },
  form: {
    step1Name: 'Personal Details',
    step1Heading: 'Personal Details',
    step2Name: 'Income',
    step2Heading: 'Income Details',
    step2SubHeadingOne: 'What is your monthly pre-tax income?',
    step2SubHeadingTwo: 'Checking Account Information',
    step3Name: 'Review',
    required: 'Required',
    firstName: {
      label: 'First Name',
      individual: 'Applications are individual. Please only enter 1 person’s name'
    },
    lastName: {
      label: 'Last Name',
      individual: 'Applications are individual. Please only enter 1 person’s name'
    },
    dob: {
      label: 'Date of Birth',
      incorrectDate: 'The date is incorrect'
    },
    emailAddress: {
      label: 'Email Address',
      required: 'Enter email address'
    },
    address: {
      label: 'Residential Address',
      placeholder: 'Enter Address'
    },
    unit: {
      label: 'Unit',
      placeholder: 'Enter #'
    },
    city: {
      label: 'City',
      placeholder: 'Enter city name'
    },
    state: {
      label: 'State',
      placeholder: 'Select'
    },
    zipCode: {
      label: 'Zip Code'
    },
    idType: {
      label: 'ID Type',
      placeholder: 'Select ID Type',
      options: [
        {
          value: 'driver_license',
          label: 'Driver License'
        },
        {
          value: 'Non-Driver / State ID',
          label: 'Non-Driver / State ID'
        },
        {
          value: 'US Military',
          label: 'US Military'
        },
        {
          value: 'US Passport',
          label: 'US Passport'
        }
      ]
    },
    idNumber: {
      label: 'ID Number'
    },
    idState: {
      label: 'ID State',
      placeholder: 'Select ID State'
    },
    monthlyIncome: {
      label: 'Monthly Income',
      msg: 'per month',
      error: 'Monthly Income must be more than $0'
    },
    paycheckFrequency: {
      label: 'Income Frequency',
      placeholder: 'Select Period',
      options: [
        {
          value: 'weekly',
          label: 'Weekly'
        },
        {
          value: 'bi-weekly',
          label: 'Every Other Week'
        },
        {
          value: 'bi-monthly',
          label: 'Twice a Month'
        },
        {
          value: 'monthly',
          label: 'Monthly'
        }
      ]
    },
    lastPayDate: {
      label: 'Last Income Date'
    },
    nextPayDate: {
      label: 'Next Income Date'
    },
    plaid: {
      label: 'Link your bank account',
      success: 'Bank account linked successfully!',
      info: 'or, enter your account details manually'
    },
    routingNumber: {
      label: 'Routing Number',
      placeholder: 'Enter Number',
      isInvalid: 'The routing number is invalid. Please correct the routing number.',
      isNotAllowed:
        'We are sorry, we do not currently accept non-traditional bank accounts such as Chime, Simple, or Varo. Please use a traditional bank account to continue.'
    },
    accountNumber: {
      label: 'Account Number',
      placeholder: 'Enter Number',
      isIncomplete: 'The account number must be at least 4 characters.'
    },
    merchantDetails: {
      label: 'Merchant Details',
      merchant: {
        label: 'Merchant'
      },
      store: {
        label: 'Store'
      }
    },
    name: 'Name',
    email: 'Email Address',
    ssn: {
      label: 'SSN/ITIN'
    }
  },
  home: 'Home',
  back: 'Back',
  terms: {
    header: 'Application Details',
    info: 'Please read and agree to the terms of the application below',
    check1: {
      text1: 'By checking this box I confirm that I have read and agree to',
      text2: "Kafene's E-sign Disclosure Notice",
      text3: 'Privacy Notice',
      text4: 'Terms and Conditions',
      text5: 'Communications Policy',
      text6: 'and',
      text7: 'Messaging Policy.',
      policy_sms: 'https://kafene.com/short-code-policies'
    },
    check2: `By checking this box, I authorize Kafene, Inc. and/or its affiliates
    ("Kafene") to obtain reports about me from consumer reporting
    agencies. I agree that Kafene may use my consumer reports for any
    lawful purpose, such as: (1) authenticating my identity; (2) making
    eligibility decisions on rental purchase agreements; (3) assisting
    with internal modeling and analysis; (4) servicing my account during
    the entire life of the Agreement; and (5) verifying information in my
    application. Reviewing your consumer report for this application will
    not negatively impact your score.`,
    check3: `I Consent: By checking this box, you authorize Kafene, Inc. and its
    agents to place telemarketing calls and texts with an automatic
    telephone dialing system to the telephone number provided on this
    application. You should understand that you do not have to check the
    box to obtain a good or service from Kafene, Inc. and certify that you
    are authorized to provide consent. -`,
    policy: 'Policy'
  },
  paymentDetails: {
    header: 'Enter Your Payment Method',
    cardName: 'Name on the Card',
    cardNumber: 'Card Number',
    zipCode: 'Zip Code',
    cardExpiry: 'Expiry'
  },
  info: {
    version3Header: 'We still need some more information.',
    header: 'More Info Needed!',
    p1: 'We need additional information to complete your application.',
    p2: 'Look out for an SMS with next steps.'
  },
  declined: {
    header: 'Sorry',
    p1: 'We are not able to approve you at this time, please try again in 30 days. You will receive an email with more information on our decision shortly.'
  },

  complianceSmsAlerts:
    'By providing your phone number you consent to receiving autodialed texts and calls for non-telemarketing purposes. Msg & Rates may apply.',
  complianceSmsAccountUpdates:
    'I agree to receive account updates at the phone number provided. Message frequency varies. Text STOP to end. Msg & Rates may apply.',
  messagingPolicyText: 'Messaging Policy.',
  messagingPolicyLink: 'https://kafe.ne/sms'
}

const es = {
  languageText: 'Switch to English',
  enterPhoneNumber: {
    header: 'Ingrese su número telefónico',
    info: 'Le enviaremos por mensaje de texto un código de verificación para iniciar o reanudar su solicitud (pueden aplicarse cargos estándar por SMS)',
    phoneNo: {
      label: 'Número de celular'
    },
    ssn: {
      label: 'SSN/ITIN'
    },
    confirmSsn: {
      label: 'Confirm SSN/ITIN',
      error: 'SSN/ITIN no coincide'
    },
    email: {
      label: 'Correo Electrónico',
      info: 'We need your email address to authenticate you'
    },
    buttonText: 'Enviar'
  },
  codeVerification: {
    header: 'Acabamos de enviarle un mensaje',
    info: 'Ingrese el código de verificación de 6 dígitos enviado a:',
    cta: 'Cambiar número',
    buttonText: 'Verificar',
    otpText: 'Reenviar el código'
  },
  form: {
    step1Name: 'Detalles Personales',
    step1Heading: 'Detalles Personales',
    step2Name: 'Sus ingresos',
    step2Heading: 'Detalles de los ingresos',
    step2SubHeadingOne: '¿Cuáles son sus ingresos mensuales antes de impuestos?',
    step2SubHeadingTwo: 'Información sobre la cuenta corriente',
    step3Name: 'Revisar',
    required: 'Required',
    firstName: {
      label: 'Primer Nombre'
    },
    lastName: {
      label: 'Apellido'
    },
    dob: {
      label: 'Fecha de nacimiento',
      incorrectDate: 'Favor de revisar la fecha de nacimiento'
    },
    emailAddress: {
      label: 'Correo Electrónico',
      required: 'Correo electrónicodel'
    },
    address: {
      label: 'Numero y Nombre de la calle',
      placeholder: 'Ingrese la dirección'
    },
    unit: {
      label: 'Unidad',
      placeholder: 'Ingrese #'
    },
    city: {
      label: 'Ciudad',
      placeholder: 'Ingrese el nombre de la ciudad'
    },
    state: {
      label: 'Estado',
      placeholder: 'Seleccione'
    },
    zipCode: {
      label: 'Código postal'
    },
    idType: {
      label: 'Tipo de identificación',
      placeholder: 'Seleccione el tipo de identificación',
      options: [
        {
          value: 'driver_license',
          label: 'Licencia de conducir'
        },
        {
          value: 'Non-Driver / State ID',
          label: 'Identificación de no conductor o del estado'
        },
        {
          value: 'US Military',
          label: 'Militares de EE.UU.'
        },
        {
          value: 'US Passport',
          label: 'Pasaporte de EE.UU.'
        }
      ]
    },
    idNumber: {
      label: 'Número de identificación'
    },
    idState: {
      label: 'Identificación estatal',
      placeholder: 'Seleccione el estado de su identificación'
    },
    monthlyIncome: {
      label: 'Ingresos mensuales',
      msg: 'por mes',
      error: 'El ingreso mensual debe ser mayor de $0.00'
    },
    paycheckFrequency: {
      label: 'Frecuencia de los cheques de pago',
      placeholder: 'Seleccione el periodo',
      options: [
        {
          value: 'weekly',
          label: 'Semanal'
        },
        {
          value: 'bi-weekly',
          label: 'Cada dos semanas'
        },
        {
          value: 'bi-monthly',
          label: 'Dos veces al mes'
        },
        {
          value: 'monthly',
          label: 'Mensualmente'
        }
      ]
    },
    lastPayDate: {
      label: 'Última fecha de pago'
    },
    nextPayDate: {
      label: 'Siguiente fecha de pago'
    },
    plaid: {
      label: 'Conéctesecon su banco',
      success: 'Bank account linked successfully!',
      info: 'o complete lo siguiente con los detalles de su cuenta.'
    },
    routingNumber: {
      label: 'Número de ruta',
      placeholder: 'Ingrese el número',
      isInvalid: 'El numero de ruta es invalido. Corrija el numero de ruta.',
      isNotAllowed:
        'Lo sentimos, por el momento no aceptamos cuentas bancarias no-tradicionales como son Chime, Simple o Varo. Favor de someter su aplicación nuevamente usando una cuenta de cheques y nuero de ruta tradicionales.'
    },
    accountNumber: {
      label: 'Número de cuenta',
      placeholder: 'Ingrese el número',
      isIncomplete: 'El numero de cuenta debe ser de por lo menos 4 caracteres.'
    },
    merchantDetails: {
      label: 'Detalles del comerciante',
      merchant: {
        label: 'Comerciante'
      },
      store: {
        label: 'Tienda'
      }
    },
    name: 'Nombre',
    email: 'Ingrese la dirección',
    ssn: {
      label: 'SSN/ITIN'
    }
  },
  home: 'Inicio',
  back: 'Espalda',
  terms: {
    header: 'Application Details',
    info: 'Lea y acepte los términos de su acuerdo.',
    check1: {
      text1: 'Al marcar la cajita, confirmo que he leídoy acepto',
      text2: 'la firma electrónica(E-sign) El Aviso de divulgación',
      text3: 'Aviso de Privacidad',
      text4: 'Términos y Condiciones',
      text5: 'Las Políticasde Comunicaciones',
      text6: 'y',
      text7: "Kafene's E-sign Disclosure Notice",
      policy_sms: 'https://kafene.com/politicas-sms'
    },
    check2: `Al marcar la cajita, autorizo a Kafene, Inc y/o sus afiliados
    (“kafene”) a obtener informaciónmíade las agencias de consumo.
    Aceptoque Kafene use mis reportesde consumopara propósitoslegales,
    como son:(1) Comprobar mi Identidad; (2) tomar decisiones acerca de mi
    elegibilidad en acuerdosde rentas con opcióna compra; (3) asistircon
    análisisy modelado interno; (4) dar mantenimiento a mi cuenta durante
    el tiempo de vida de mi acuerdo; y (5) verificar la informaciónen mi
    aplicación. Revisar su reporte de consume para esta aplicaciónno
    afecta negativamente su puntaje.`,
    check3: `Yo consiento: Al seleccionarla cajita, y autorizo a Kafene, Inc. y
    sus agentes, de hacer llamadas de mercadeo y enviar textos con un
    Sistema telefónicoautomáticoal numero telefónicoincluido en esta
    aplicación. Usted debe saber y entender que el no seleccionarla cajita
    no afecta el buen servicio que usted obtiene de Kafene, Inc. y
    certifica que usted autoriza proveer este consentimiento. -`,
    policy: 'Política.'
  },
  paymentDetails: {
    header: 'Ingrese su método de pago',
    cardName: 'Nombre en la tarjeta',
    cardNumber: 'Número de tarjeta',
    zipCode: 'Código postal',
    cardExpiry: 'Vencimiento'
  },
  info: {
    version3Header: 'We still need some more information.',
    header: 'Se necesita más información.',
    p1: 'Necesitamos información adicional para completar su solicitud.',
    p2: 'Esté atento a un SMS con los próximos pasos.'
  },
  declined: {
    header: '¡Lo sentimos',
    p1: 'En este momento no puede ser aprobado, por favor inténtelo de nuevo en 30 días. En breve recibirá un correo electrónico con más información sobre nuestra decisión.'
  },
  complianceSmsAlerts:
    'Al proporcionar tu número telefónico aceptas recibir textos y llamadas automatizados con propósitos diferentes a telemercadeo. Tarifa de mensajería podría aplicarse.',
  complianceSmsAccountUpdates:
    'Acepto recibir actualizaciones de mi cuenta en el número telefónico que proporcione. La frecuencia de los mensajes varia. Envía STOP para salir. Tarifa de mensajería podría aplicarse.',
  messagingPolicyText: 'Políticas de Mensajería.',
  messagingPolicyLink: 'https://kafene.com/politicas-sms'
}

export const getLocal = (lang, path) => {
  return get(lang === 'Spanish' ? es : en, path, '')
}
