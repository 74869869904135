// [varUrl, varStore, store]
const urlVariables = [
  ['v', 'version', 'application'],
  ['merchant', 'merchantId', 'application'],
  ['store', 'storeId', 'application'],
  ['employee', 'salesPerson', 'application'],
  ['source', 'source', 'application'],
  ['utm_source', 'utmSource', 'application'],
  ['utm_medium', 'utmMedium', 'application'],
  ['utm_campaign', 'utmCampaign', 'application'],
  ['phone', 'phoneNumber', 'user'],
  ['token', 'cardAuthToken', 'application'],
  ['iframe', 'iframe', '']
]

export { urlVariables }
