import { ENDPOINTS, postWithHeader } from '../../helpers/api'
import { unformatMoney } from '../../helpers/general'

import { damageStates } from '../../data/states'

const cart = {
  namespaced: true,
  state: () => ({
    items: [],
    shipment: '',
    downpayment: '0.000',
    assetPrice: '0.00',
    agreement: false
  }),
  mutations: {
    setShopifyItems(state, { items }) {
      state.items = items.map(({ title, final_line_price, quantity, sku }, index) => ({
        key: `itemKey${index}`,
        description: `${title}, Qty - ${quantity}`,
        price: `${final_line_price}`,
        model: sku ? `SKU - ${sku}` : ``
      }))
    },
    setItems(state, items) {
      state.items = items
    },
    setShipment(state, amount) {
      state.shipment = amount
    },
    setAssetPrice(state, amount) {
      state.assetPrice = amount
    },
    setAgreement(state, { value }) {
      state.agreement = value
    }
  },
  getters: {
    getItems(state) {
      return state.items
    },
    getShipment(state) {
      return state.shipment
    },
    getSum(state) {
      return state.items.reduce((acc, { price }) => acc + unformatMoney(price), 0) || unformatMoney(state.assetPrice)
    },
    getSumWithShipment(state) {
      return (
        (state.items.reduce((acc, { price }) => acc + unformatMoney(price), 0) || unformatMoney(state.assetPrice)) +
        unformatMoney(state.shipment)
      )
    },
    getAgreement(state) {
      return state.agreement
    }
  },
  actions: {
    async saveItems(context) {
      const items = context.getters.getItems

      const state = context.rootGetters['application/getApplicantState']

      const response = await postWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.itemsCreate, {
        applicationId: context.rootGetters['application/getApplicationId'],
        items: items.map((item) => ({
          description: item.description,
          model_num: item.model,
          price: unformatMoney(item.price),
          item_condition: 'New',
          ...(damageStates.includes(state.value) && { damage: 'none' })
        })),
        shippingCost: unformatMoney(context.getters.getShipment),
        assetPrice: parseFloat(context.getters.getSum)
      })

      const resp = await response.json()

      return resp
    }
  }
}

export { cart }
