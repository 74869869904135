import { ENDPOINTS, post } from '../../helpers/api'

const user = {
  namespaced: true,
  state: () => ({
    previouslySavedPhoneNumber: '', // there will be a value here if user logs in with an existing email but has a phone number that does not match in database
    phoneNumber: '',
    userId: '',
    userType: '',
    authToken: '',
    email: ''
  }),
  mutations: {
    setPhoneNumber(state, { phoneNumber }) {
      state.phoneNumber = phoneNumber
    },
    setEmail(state, { email }) {
      state.email = email
    },
    setUserId(state, { id }) {
      state.userId = id
    },
    setAuthToken(state, { token }) {
      state.authToken = token
    },
    setPreviouslySavedPhoneNumber(state, { previouslySavedPhoneNumber }) {
      state.previouslySavedPhoneNumber = previouslySavedPhoneNumber
    },
    setUserType(state, { userType }) {
      state.userType = userType
    }
  },
  getters: {
    getEmail(state) {
      return state.email
    },
    getPhoneNumber(state) {
      return state.phoneNumber
    },
    getUserId(state) {
      return state.userId
    },
    getAuth(state) {
      return {
        authorization: state.authToken
      }
    }
  },
  actions: {
    // TO DO:
    // * check for success: false
    // * make it DRY
    async verifyEmail(context, { phoneNumber, email }) {
      const response = await post(ENDPOINTS.verifyEmail, {
        phoneNumber,
        email
      })

      const resp = await response.json()

      if (resp.success) {
        const { data } = resp

        if (data.action === 'otp') {
          const { user } = data
          context.commit('setUserId', { id: user.id })
          context.commit('setPhoneNumber', { phoneNumber: user.phoneNumber })
          context.commit('setEmail', { email: user.email })
          if (user.oldPhoneNumber) {
            context.commit('setPreviouslySavedPhoneNumber', {
              previouslySavedPhoneNumber: user.oldPhoneNumber
            })
          }
        }
      }

      return resp
    },
    async verifyLoginByEmail(context, { phoneNumber, email }) {
      const response = await post(ENDPOINTS.verifyLoginByEmail, {
        phoneNumber,
        email
      })

      const resp = await response.json()

      if (resp.success) {
        const { data } = resp

        if (data.action === 'otp') {
          const { user } = data
          context.commit('setUserId', { id: user.id })
          context.commit('setPhoneNumber', { phoneNumber: user.phoneNumber })
          context.commit('setEmail', { email: user.email })
        }
      }

      return resp
    },
    async registerOrLogin(context, { phoneNumber, ssn }) {
      const response = await post(ENDPOINTS.registerOrLogin, {
        phoneNumber,
        ssn
      })

      const resp = await response.json()

      if (resp.success) {
        const { data } = resp

        if (data.action === 'otp') {
          const { user } = data
          context.commit('setUserId', { id: user.id })
          context.commit('setPhoneNumber', { phoneNumber: user.phoneNumber })
          // context.commit("setEmail", { email: user.email });
        }
        context.commit('setPhoneNumber', { phoneNumber })
        context.commit('setUserType', { userType: data.response.userType })
      }

      return resp
    },
    async resendOtp(context) {
      const response = await post(ENDPOINTS.resendOtp, {
        phoneNumber: context.state.phoneNumber,
        userId: context.state.userId
      })

      const resp = await response.json()

      return resp
    },
    async login(context, { token, newPhoneNumberToSave, phone }) {
      let payload = {
        // token,
        // email: context.state.email,
        userId: context.state.userId,
        phoneNumber: phone,
        otpToken: token
      }

      if (newPhoneNumberToSave) {
        payload.newPhoneNumberToSave = newPhoneNumberToSave // if user logs in with an existing email but new phone number, we want to save this once user successfully logs in with correct token
      }

      const response = await post(ENDPOINTS.login, payload)

      const resp = await response.json()

      if (resp.success) {
        const { data } = resp
        const { authToken, user } = data

        // if user is a returning user, initial call to get OTP would return an authToken
        // otherwise authToken would still be empty at this point if the user is new. (BE generates authtoken for an empty user)
        if (authToken && !context.state.authToken) context.commit('setAuthToken', { token: authToken })

        if (user) {
          context.dispatch(
            'application/setFields',
            {
              formData: {
                firstName: user.firstName || '',
                lastName: user.lastName || ''
              }
            },
            { root: true }
          )
        }
      }

      return resp
    }
  }
}

export { user }
