<template>
  <ul
    class="alert"
    :class="{
      'alert--primary': primary,
      'alert--dimmed': dimmed
    }"
  >
    <li class="alert__label">Due today:</li>

    <li class="alert__value">$83.11</li>
  </ul>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    label: String,
    value: String,
    primary: Boolean,
    dimmed: Boolean
  }
}
</script>

<style lang="scss" scoped>
.alert {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  padding: 13px 24px;
  border-radius: 8px;

  @include media-query(md) {
    flex-direction: row;
    align-items: center;
  }

  &__label {
    flex: 1 1 auto;
  }

  &__value {
    flex: 0 0 auto;

    @include media-query(md) {
      margin-left: 8px;
    }
  }

  &--primary {
    color: #ffffff;
    background-color: #00e091;
  }

  &--dimmed {
    color: #ffffff;
    background-color: #4e5d78;
  }
}
</style>
