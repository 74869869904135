<template>
  <div class="details">
    <div class="details__content">
      <H class="details__heading" h3>James’s Lease Details</H>

      <div class="details__section details__section--top">
        <Alert label="Due today:" value="$83.11" primary />
      </div>

      <div class="details__section details__section--distant">
        <div class="table">
          <div class="table__header">
            <H class="table__heading" is="h4">Other</H>
          </div>

          <div class="table__item">
            <p class="table__label">First Payment</p>

            <p class="table__value">$45.00</p>
          </div>

          <div class="table__item">
            <p class="table__label">Delivery</p>

            <p class="table__value">$35.00</p>
          </div>

          <div class="table__item">
            <p class="table__label">Tax</p>

            <p class="table__value">$3.11</p>
          </div>

          <div class="table__item">
            <p class="table__label table__label--strong">Total due today</p>

            <p class="table__value table__value--strong">$83.11</p>
          </div>
        </div>
      </div>

      <div class="details__section details__section--xdistant">
        <Alert label="Pay over time:" value="$20.10" dimmed />
      </div>

      <div class="details__section details__section--close">
        <div class="table">
          <div class="table__header">
            <H class="table__heading" is="h4">Leasable items:</H>
          </div>

          <div v-for="{ id, title, quantity } in cartItems" :key="id" class="table__item">
            <p class="table__label table__label--narrow">{{ title }}</p>

            <p class="table__value">QTY {{ quantity }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="details__footer">
      <Button large link icon @click="$emit('footerButtonClick')">
        <template v-slot:icon>
          <svg width="7" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6 1L1.75 5.25 6 9.5"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </template>

        <span>Back to Overview</span>
      </Button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'

import Alert from './common/Alert'
import Button from './common/Button'
import H from './common/H'

export default {
  name: 'LeaseDetails',
  components: {
    Alert,
    Button,
    H
  },
  setup() {
    return {
      store: useStore()
    }
  },
  computed: {
    cartItems() {
      return this.store.getters['cart/getItems']
    }
  }
}
</script>

<style lang="scss">
.details {
  padding: 40px 20px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  @include media-query(sm) {
    padding: 40px;
    padding-top: 80px;
  }

  &__content {
    flex: 1 1 auto;
  }

  &__section {
    &--top {
      margin-top: 24px;
    }

    &--distant {
      margin-top: 12px;

      @include media-query(sm) {
        margin-top: 24px;
      }
    }

    &--xdistant {
      margin-top: 32px;
    }

    &--close {
      margin-top: 12px;
    }
  }

  &__heading {
    color: #323b4b;
  }

  &__footer {
    text-align: center;
    flex: 0 0 auto;
    margin-top: 40px;
  }
}

.table {
  $this: &;

  color: #323b4b;

  &__header {
    padding: 8px 0;
    border-bottom: 2px solid rgba(176, 183, 195, 0.3);
    margin-bottom: 8px;
  }

  &__heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    color: #323b4b;
  }

  &__item {
    padding: 8px 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__label {
    flex: 1 1 auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;

    &--narrow {
      flex: 0 1 181px;
    }

    &--strong {
      font-weight: 800;
    }
  }

  &__value {
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    margin-left: 8px;

    &--strong {
      font-weight: 800;
    }
  }

  &__footer {
    border-top: 2px solid rgba(176, 183, 195, 0.3);
    margin-top: 8px;
    padding-top: 8px;
  }
}
</style>
