import { views, sections, inputs, data } from '../../data/application-form'

import { get } from 'lodash'

import { ENDPOINTS, postWithHeader, getWithHeader, putWithHeader } from '../../helpers/api'

import { mask } from '../../helpers/general'
import router from '../../router'

const stateInputs = inputs(sections(views)).filter((input) => input.type !== 'preview')

const formatApplicationObject = (app, ssnITIN, phone, language) => ({
  source: app.source,
  sourceDetails: `${app.utmMedium}; ${app.utmSource}; ${app.utmCampaign}`,
  onlineVersion: app.version,
  salesPerson: app.salesPerson,
  firstName: app.firstName,
  lastName: app.lastName,
  email: app.emailAddress,
  phone,
  dob: app.dateOfBirth,
  ssn: app.ssnITIN,
  ssnITIN,
  id: {
    type: app.idType.value,
    number: app.idNumber,
    state: app.idState.value || app.state.value
  },
  address: {
    street: app.streetAddress,
    apt: app.aptNumber,
    zipcode: app.zipCode,
    city: app.city,
    state: app.state.value
  },
  bank: {
    routingNumber: app.routingNumber,
    accountNumber: app.accountNumber
  },
  plaid: {
    bankSource: app.plaid.bankSource || null,
    accountId: app.plaid.accountId || null
  },
  income: {
    frequency: app.paycheckFrequency.value,
    monthlyIncome: parseFloat(app.monthlyIncome.replace(/[^0-9.]/g, '')),
    lastPayDate: app.lastPayDate,
    nextPayDate: app.nextPayDate
  },
  employersName: '',
  category: '',
  agreement: {
    esign: app.agreementEsign,
    fcra: app.agreementFcra,
    contact: app.agreementContact
  },
  merchantId: app.merchantId,
  storeId: app.storeId,
  ioBb: typeof window.IGLOO?.getBlackbox === 'function' ? window.IGLOO?.getBlackbox()?.blackbox : null,
  window_SOCURE_sessionId: window.SOCURE?.sessionId || null,
  language
})

const application = {
  namespaced: true,
  state: () => ({
    ...data(stateInputs),
    source: 'direct',
    salesPerson: '',
    merchantId: '',
    storeId: '',
    merchantName: '',
    storeName: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    amount: 0,
    applicationId: '',
    tier: '',
    tierName: '',
    actionRequired: '',
    version: 'v1',
    agreementEsign: false,
    agreementFcra: false,
    agreementContact: false,
    cardAuthToken: '',
    activeCardId: '',
    ssnITIN: ''
  }),
  getters: {
    // getFields :: Object -> ([String] -> Object)
    getFields:
      (state) =>
      (fields = stateInputs.map((i) => i.name)) =>
        fields.reduce((acc, field) => ({ ...acc, [field]: state[field] }), {}),
    getState(state) {
      return state
    },
    getApplicantState(state) {
      return state.state
    },
    getFirstName(state) {
      return state.firstName
    },
    getLastName(state) {
      return state.lastName
    },
    getName(state) {
      return `${state.firstName} ${state.lastName}`
    },
    getAmount(state) {
      return state.amount
    },
    getEmail(state) {
      return state.emailAddress
    },
    getDOB(state) {
      return state.dateOfBirth
    },
    getApplicationId(state) {
      return state.applicationId
    },
    getAgreementContact(state) {
      return state.agreementContact
    },
    getMaskedAccountNumber(state) {
      return mask(state.accountNumber)
    },
    getMaskedSsnITIN(state) {
      return mask(state.ssnITIN)
    },
    getSsnItinLast4(state) {
      return state.ssnITIN.slice(-4)
    },
    getPlaid(state) {
      return state.plaid
    },
    getStatus(state) {
      return state.status
    },
    getStoreId(state) {
      return state.storeId
    },
    getStoreName(state) {
      return state.storeName
    },
    getMerchantName(state) {
      return state.merchantName
    },
    getVersion(state) {
      return state.version
    },
    getActiveCardId(state) {
      return state.activeCardId
    },
    getCardAuthToken(state) {
      return state.cardAuthToken
    },
    getSSN(state) {
      return state.ssnITIN
    }
  },
  mutations: {
    setField(state, { field, value }) {
      state[field] = value
    },
    setStatus(state, value) {
      state.status = value
    },
    setAmount(state, value) {
      state.amount = value
    },
    setApplicationId(state, value) {
      state.applicationId = value
    },
    setTier(state, value) {
      state.tier = value
    },
    setTierName(state, value) {
      state.tierName = value
    },
    setActionRequired(state, value) {
      state.actionRequired = value
    },
    setAgreementContact(state, value) {
      state.agreementContact = value
    },
    setPlaid(state, { bankSource, accountId }) {
      state.plaid = { bankSource, accountId }
    },
    setStoreName(state, value) {
      state.storeName = value
    },
    setMerchantName(state, value) {
      state.merchantName = value
    },
    setActiveCardId(state, value) {
      state.activeCardId = value
    },
    setCardAuthToken(state, value) {
      state.cardAuthToken = value
    },
    setVersion(state, value) {
      state.version = value
    },
    setSSN(state, value) {
      state.ssnITIN = value
    }
  },
  actions: {
    setFields(context, { formData }) {
      Object.entries(formData).map(([field, value]) => {
        context.commit('setField', {
          field,
          value
        })
      })
    },
    setApplicationDetails(context, responseData) {
      const { status, amount, applicationId, tier, actionRequired } = responseData

      context.commit('setStatus', status)
      context.commit('setAmount', amount)
      context.commit('setApplicationId', applicationId)
      context.commit('setTier', tier)
      context.commit('setActionRequired', actionRequired)
    },
    async applicationStatus(context) {
      const auth = context.rootGetters['user/getAuth']

      const { authorization } = auth

      if (!authorization) {
        return {
          success: false
        }
      }

      const response = await getWithHeader(auth)(ENDPOINTS.home)

      const resp = await response.json()

      return resp
    },
    async sendApplication(context) {
      const response = await postWithHeader(context.rootGetters['user/getAuth'])(
        ENDPOINTS.createApplication,
        formatApplicationObject(
          context.getters.getState,
          context.rootGetters['application/getSSN'],
          context.rootGetters['user/getPhoneNumber'],
          context.rootGetters.getLanguage
        )
      )

      const resp = await response.json()

      const statusesMap = {
        default: '/manual-review',
        manual: '/manual-review',
        approved: '/approval',
        denied: '/declined'
      }

      if (resp.success) {
        router.push({ path: statusesMap[resp.data?.status || 'default'] })
        context.commit('setAmount', resp.data?.amount)
        context.commit('setApplicationId', resp.data?.applicationId)
        await context.commit('user/setAuthToken', { token: get(resp, 'data.authToken', '') }, { root: true })
        return
      }

      if (resp.data?.type === 'Knockout') {
        router.push('/declined')
        return
      }

      return resp
    },
    async merchantDetails(context) {
      const response = await postWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.store, {
        id: context.getters.getStoreId,
        includeMerchant: true
      })

      const resp = await response.json()

      context.commit('setStoreName', resp.data.store_name)
      context.commit('setMerchantName', resp.data.merchant_name)
    },
    async createCard(context) {
      const response = await postWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.createCard, {
        applicationId: context.getters.getApplicationId,
        amount: context.getters.getAmount
      })

      const resp = await response.json()

      return resp
    },
    async unlockCard(context, { cardId, ssnLastFour }) {
      const response = await postWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.unlockCard, {
        applicationId: context.getters.getApplicationId,
        cardId,
        dateOfBirth: context.getters.getDOB,
        ssnLastFour
      })

      const resp = await response.json()

      return resp
    },
    async setPricing(context) {
      const response = await putWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.setPricing, {
        applicationId: context.getters.getApplicationId
      })

      const resp = await response.json()

      return resp
    },
    async getCardAccessLink(context) {
      const response = await postWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.cardAccessLink, {
        applicationId: context.getters.getApplicationId,
        cardId: context.getters.getActiveCardId
      })

      const resp = await response.json()

      return resp
    },
    async getCardDetails(context) {
      const response = await postWithHeader(context.rootGetters['user/getAuth'])(ENDPOINTS.cardDetails, {
        token: context.getters.getCardAuthToken
      })

      const resp = await response.json()

      return resp
    }
  }
}

export { application }
