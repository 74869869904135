<template>
  <div
    class="input"
    :class="{
      'input--borderless': borderless,
      'input--digit': digit,
      'input--error': error,
      'input--dropdown': dropdown,
      'input--small': small,
      'input--radiusless': radiusless,
      'input--radiusless-top': radiuslessTop,
      'input--radiusless-bottom': radiuslessBottom
    }"
  >
    <div class="input__wrap">
      <label v-if="label && !digit" :for="name ? name : uuid" class="input__label" :data-label-lg="labelLg || label">
        <span>{{ label }}</span>
      </label>

      <p v-if="!!suggestion && !!value" class="input__suggestion">
        <span
          v-for="(part, index) in suggestionParts"
          :key="part"
          :class="{
            'input__suggestion-hidden-part': index === 0
          }"
          >{{ part }}</span
        >
      </p>

      <div v-if="proxy" ref="proxy"></div>

      <div class="input__container" v-else>
        <input
          :id="name ? name : uuid"
          :name="name"
          class="input__input"
          :disabled="disabled"
          :type="type"
          :inputmode="inputmode"
          :placeholder="placeholder"
          :value="value"
          :maxlength="digit && 1"
          ref="input"
          v-maska="mask"
          @input.stop="$emit('update:value', $event.target.value)"
          @blur="$emit('blur', $event)"
          @focus="focus"
        />

        <div class="input__msg" v-if="msg">
          {{ msg }}
        </div>
      </div>
    </div>

    <p v-if="info" class="input__info" :class="{ 'input__info--error': error }">
      {{ info }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    borderless: Boolean,
    dropdown: Boolean,
    error: Boolean,
    label: String,
    name: {
      type: String,
      default: ''
    },
    suggestion: {
      type: String,
      default: ''
    },
    labelLg: String,
    placeholder: String,
    value: String,
    digit: Boolean,
    mask: [String, Array, Object],
    type: {
      type: String,
      default: 'text'
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    small: Boolean,
    info: {
      type: String,
      default: ''
    },
    proxy: {
      type: Boolean,
      default: false
    },
    radiusless: {
      type: Boolean,
      default: false
    },
    radiuslessTop: {
      type: Boolean,
      default: false
    },
    radiuslessBottom: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    msg: {
      type: String,
      default: ''
    }
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        this.$refs.input.select()
        this.$refs.input.focus()
      })
    },
    focus($event) {
      if (this.digit) {
        this.$refs.input.select()
      }
      this.$emit('focus', $event)
    }
  },
  computed: {
    suggestionParts() {
      const splitPoint = this.suggestion.indexOf(this.value) + this.value.length

      return [this.suggestion.slice(0, splitPoint), this.suggestion.slice(splitPoint)]
    }
  }
}
</script>

<style lang="scss">
.input {
  $this: &;

  &__wrap {
    position: relative;
    border: 1px solid #f3f3f3;
    background-color: #fff;
    border-radius: 4px;

    &:focus-within {
      border-color: rgba(get-color(primary-1), 0.4);
      box-shadow: 0 0 10px rgba(get-color(primary-1), 0.2);
    }
  }

  &__label {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 16px;
    padding: 0 4px;
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 16px;
    color: #4e5d78;
    line-height: 18px;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 4px;
      background-color: #fff;
    }

    > span {
      display: none;

      @include media-query(lg) {
        display: inline;
      }
    }

    &::after {
      content: attr(data-label-lg);

      @include media-query(lg) {
        display: none;
      }
    }
  }

  &__suggestion {
    position: absolute;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden;
    top: 50%;
    transform: translateY(-50%);
    color: #b0b7c3;
    white-space: nowrap;
  }

  &__suggestion-hidden-part {
    visibility: hidden;
  }

  &__input {
    position: relative;
    font-family: 'Inter', sans-serif;
    display: block;
    width: 100%;
    line-height: 58px;
    height: 58px;
    padding-left: 20px;
    padding-right: 20px;
    border: 0;
    border-radius: inherit;

    font-weight: 500;
    font-size: 16px;
    color: #323b4b;
    background-color: rgba(0, 0, 0, 0);

    &::placeholder {
      font-weight: 500;
      font-size: 16px;
      color: #b0b7c3;
      font-family: 'Inter', sans-serif;
    }

    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type='number'] {
      -moz-appearance: textfield; /* Firefox */
    }

    &:disabled {
      background-color: get-color(bg-3);
    }

    &:focus {
      outline: 0;
    }
  }

  &__info {
    font-size: 12px;
    line-height: 16px;
    padding-top: 3px;
    color: get-color(text-3);
    text-align: left;

    &--error {
      color: get-color(primary-4);
    }
  }

  &__stripe {
    padding-left: 20px;
    padding-right: 20px;
  }

  &__container {
    display: flex;
  }

  &__msg {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #22c58b;
    height: 58px;
    width: 140px;
    margin-left: -19px;
    z-index: 5;
    border-radius: 0 0.2rem 0.2rem 0;
    color: #fff;
  }

  &--digit {
    max-width: 50px;

    #{$this}__wrap {
      border-color: #b0b7c3;
    }

    #{$this}__input {
      padding-left: 8px;
      padding-right: 8px;
      text-align: center;
    }
  }

  &--borderless {
    #{$this}__wrap {
      border: 0;
    }
  }

  &--dropdown {
    #{$this}__wrap {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &--small {
    #{$this}__input {
      height: 48px;
    }
  }

  &--radiusless {
    #{$this}__wrap {
      border-radius: 0;
    }
  }

  &--radiusless-top {
    #{$this}__wrap {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &--radiusless-bottom {
    #{$this}__wrap {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &--error {
    #{$this}__wrap {
      border-color: get-color(primary-4);
      z-index: 1;
    }

    #{$this}__info {
      color: get-color(primary-4);
    }
  }
}
</style>
