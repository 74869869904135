<template>
  <div class="features">
    <div class="features__feature">
      <Media heading="Apply in Minutes" paragraph="Share a little info to get started.">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 24 32">
          <path
            d="M23.152 0H.643A.655.655 0 000 .667v30.666c0 .369.288.667.643.667h22.509a.655.655 0 00.643-.667V.667A.655.655 0 0023.152 0zm-.643 30.667H1.286V1.333H22.51v29.334zM5.466 20c0 3.682 2.88 6.667 6.431 6.667 3.552 0 6.431-2.985 6.431-6.667s-2.879-6.667-6.43-6.667c-3.552 0-6.432 2.985-6.432 6.667zm11.576 0c0 2.945-2.303 5.333-5.145 5.333-2.841 0-5.144-2.387-5.144-5.333 0-2.945 2.303-5.333 5.144-5.333 2.842 0 5.145 2.388 5.145 5.333zM4.18 5.333c0-.368.288-.666.643-.666h10.29c.355 0 .643.298.643.666a.655.655 0 01-.643.667H4.823a.655.655 0 01-.643-.667zm0 3.334c0-.369.288-.667.643-.667H8.04c.355 0 .643.298.643.667a.655.655 0 01-.643.666H4.823a.655.655 0 01-.643-.666zm7.074 13.06l3.402-3.534a.63.63 0 01.914 0 .687.687 0 010 .947l-3.86 4a.632.632 0 01-.456.197.632.632 0 01-.456-.197l-1.93-2a.686.686 0 01-.167-.647.658.658 0 01.457-.473.63.63 0 01.624.173l1.472 1.534z"
            fill="#5AD9AC"
          />
        </svg>
      </Media>
    </div>

    <div class="features__feature">
      <Media heading="Get Approved in Seconds" paragraph="Enjoy customized pricing options unique to your needs.">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <path
            d="M21.333 10.667C21.333 4.785 16.548 0 10.667 0 4.785 0 0 4.785 0 10.667c0 5.881 4.785 10.666 10.667 10.666C10.667 27.215 15.452 32 21.333 32 27.215 32 32 27.215 32 21.333c0-5.881-4.785-10.666-10.667-10.666zm-10.666 8.889c-4.902 0-8.89-3.988-8.89-8.89 0-4.9 3.988-8.888 8.89-8.888 4.901 0 8.889 3.987 8.889 8.889 0 .05-.004.1-.004.152a10.6 10.6 0 00-4.07 1.602l-3.926-2.267v-7.54H9.778v8.566l4.21 2.43a10.654 10.654 0 00-3.17 5.942c-.05 0-.1.004-.151.004zm10.666 10.666c-4.901 0-8.889-3.987-8.889-8.889 0-4.901 3.988-8.889 8.89-8.889 4.9 0 8.888 3.988 8.888 8.89 0 4.9-3.987 8.888-8.889 8.888z"
            fill="#5AD9AC"
          />
          <path
            d="M22.222 20.241v-3.018c.41.306.68.79.68 1.34h1.778a3.457 3.457 0 00-2.458-3.305v-2.03h-1.778v1.977a3.455 3.455 0 00-2.666 3.357c0 1.633 1.14 3 2.666 3.357v3.75a1.988 1.988 0 01-1.098-1.774H17.57a3.768 3.768 0 002.875 3.654v1.784h1.778V27.55a3.768 3.768 0 002.876-3.654 3.768 3.768 0 00-2.876-3.654zm-1.778-.21a1.668 1.668 0 01-.889-1.469c0-.638.364-1.186.89-1.469v2.938zm1.778 5.639v-3.55c.65.328 1.098 1 1.098 1.775 0 .776-.448 1.448-1.098 1.775z"
            fill="#5AD9AC"
          />
        </svg>
      </Media>
    </div>

    <div class="features__feature">
      <Media
        heading="Lease What You Love"
        paragraph="Find the option that's right for you and start enjoying your product(s) today!"
      >
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="41" height="24" viewBox="0 0 41 24">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.646 2.79l-.583 1.205 2.756 1.418h2.772V4.066h-2.465l-2.48-1.276z"
            fill="#5AD9AC"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.924 4.218l-3.15 4.864a.692.692 0 00-.034.693c.114.22.336.357.578.357h3.227a1.95 1.95 0 001.634-.9l.532-.822a.649.649 0 01.544-.3h.867c.129 0 .256.04.363.114l10.013 6.868c.476.327.583.976.24 1.447l-.041.056a.955.955 0 01-1.289.26l-5.28-3.26a.64.64 0 00-.673-.035.678.678 0 00-.347.594c0 .25.132.478.345.596l5.28 3.26a2.256 2.256 0 003.012-.605l.041-.055c.784-1.076.525-2.633-.562-3.38L21.21 7.1a1.924 1.924 0 00-1.09-.34h-.866a1.95 1.95 0 00-1.634.9l-.531.821a.649.649 0 01-.545.3h-2.002l2.47-3.813c.364-.562.977-.9 1.634-.9h4.124c.182 0 .361.025.537.077l3.74 1.1c.473.14.98.092 1.42-.135l.853-.44a.685.685 0 00.293-.906.648.648 0 00-.88-.3l-.855.438a.628.628 0 01-.47.045l-3.74-1.1a3.165 3.165 0 00-.898-.13h-4.124a3.253 3.253 0 00-2.723 1.501z"
            fill="#5AD9AC"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.098 10.384l-2.618 3.369 1.022.84 2.618-3.37-1.022-.839zM7.235 11l-.925.953 2.618 2.694.926-.952L7.235 11z"
            fill="#5AD9AC"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.285.028a.652.652 0 00-.524.321L5.249 12.596l-4.035-2.373a.639.639 0 00-.672-.019.678.678 0 00-.333.6.676.676 0 00.356.587l4.576 2.695c.305.18.693.08.88-.227l7.847-12.792a.69.69 0 00.018-.703.65.65 0 00-.601-.336z"
            fill="#5AD9AC"
          />
          <path
            d="M13.533 12.866a1.942 1.942 0 00-1.23.855l-2.595 4.053c-.586.914-.333 2.166.557 2.765l.067.048h.002c.89.6 2.104.34 2.69-.574l2.595-4.053c.586-.914.333-2.166-.557-2.766l-.067-.047h-.002a1.894 1.894 0 00-1.46-.281zm.741 1.408h.003l.066.047h.003a.64.64 0 01.179.897l-2.595 4.053a.612.612 0 01-.877.19l-.07-.048h-.002a.64.64 0 01-.179-.897l2.595-4.053a.612.612 0 01.877-.19z"
            fill="#5AD9AC"
          />
          <path
            d="M15.018 15.497a1.956 1.956 0 00-1.242.866l-1.793 2.819c-.589.927-.333 2.193.568 2.8.9.606 2.129.34 2.718-.587l1.792-2.819c.59-.927.334-2.19-.567-2.797a1.91 1.91 0 00-1.476-.282zm.76 1.41c.313.212.394.61.189.933l-1.792 2.818a.63.63 0 01-.908.195.668.668 0 01-.19-.932l1.793-2.818a.63.63 0 01.908-.195z"
            fill="#5AD9AC"
          />
          <path
            d="M16.76 17.584c-.493.11-.946.41-1.238.877l-1.166 1.86c-.583.931-.32 2.192.583 2.795.904.602 2.132.33 2.715-.6l1.166-1.86c.584-.932.321-2.193-.583-2.796a1.912 1.912 0 00-1.477-.276zm.767 1.405c.313.21.397.611.194.935l-1.166 1.863a.626.626 0 01-.903.2.673.673 0 01-.196-.934l1.168-1.864a.626.626 0 01.903-.2zM10.424 12.85c-.493.11-.944.41-1.235.876l-1.169 1.86c-.583.932-.318 2.193.586 2.795.903.603 2.129.332 2.713-.6l1.165-1.86c.584-.931.321-2.192-.583-2.795a1.911 1.911 0 00-1.477-.276zm.767 1.408a.67.67 0 01.194.931l-1.166 1.864a.625.625 0 01-.903.2.673.673 0 01-.194-.935l1.166-1.863a.625.625 0 01.903-.197z"
            fill="#5AD9AC"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M27.145.369a.69.69 0 00.024.697l7.846 12.792a.644.644 0 00.88.226l4.577-2.694a.676.676 0 00.355-.587.678.678 0 00-.333-.6.639.639 0 00-.672.018l-4.034 2.374L28.276.347a.652.652 0 00-.543-.323.651.651 0 00-.588.345zM22.456 16.187a.66.66 0 00-.617.502.683.683 0 00.295.75l3.684 2.369c1.162.747 2.701.438 3.506-.703l.562-.797a.691.691 0 00.082-.681.657.657 0 00-.54-.403.649.649 0 00-.603.292l-.562.797a1.275 1.275 0 01-1.752.35l-3.681-2.368a.642.642 0 00-.374-.108z"
            fill="#5AD9AC"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.186 18.881a.658.658 0 00-.654.496c-.08.302.05.62.316.77l2.989 1.758c1.191.7 2.72.364 3.526-.779l.578-.82a.688.688 0 00-.145-.944.644.644 0 00-.916.148l-.578.824a1.356 1.356 0 01-1.813.4l-2.992-1.758a.642.642 0 00-.311-.094z"
            fill="#5AD9AC"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18.135 20.984l-.47 1.26 3.927 1.543a2.24 2.24 0 002.708-.9l.572-.903-1.097-.74-.572.903a.937.937 0 01-1.143.38l-3.925-1.543z"
            fill="#5AD9AC"
          />
        </svg>
      </Media>
    </div>

    <div class="features__image">
      <img src="@/assets/illustration.png" alt="" />
    </div>
  </div>
</template>

<script>
import Media from './common/Media.vue'

export default {
  components: {
    Media
  }
}
</script>

<style lang="scss">
.features {
  padding: 40px 20px;

  @include media-query(sm) {
    padding: 80px 24px;
    padding-bottom: 40px;
  }

  &__feature {
    &:not(:first-child) {
      margin-top: 24px;

      @include media-query(sm) {
        margin-top: 36px;
      }
    }
  }

  &__image {
    margin-top: 40px;

    @include media-query(sm) {
      margin-top: 72px;
    }

    > img {
      max-width: 100%;

      @include media-query(sm) {
        max-width: 80%;
        margin: 0 auto;
      }
    }
  }
}
</style>
