<template>
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 32">
    <path
      d="M11.851 5.354v21.372c0 .7-.277 1.371-.771 1.866a2.629 2.629 0 01-1.861.773H2.632a2.63 2.63 0 01-1.861-.773A2.641 2.641 0 010 26.726V8a2.64 2.64 0 011.625-2.436c.32-.132.662-.2 1.007-.2l9.219-.01zM20.526 20.503a2.669 2.669 0 00-.445-.378 2.601 2.601 0 00-1.465-.449 2.63 2.63 0 00-1.861.773 2.641 2.641 0 00-.771 1.866v7.05c0 .699.278 1.37.772 1.863a2.63 2.63 0 001.86.772h7.002a2.628 2.628 0 002.322-1.4 2.644 2.644 0 00-.416-3.056l-6.998-7.041z"
      fill="#00E091"
    />
    <path
      opacity=".45"
      d="M2.632 29.364l9.208-9.228V5.354H2.632A2.625 2.625 0 00.2 6.98c-.132.32-.2.663-.2 1.009v18.726c0 .7.277 1.371.771 1.866a2.63 2.63 0 001.861.773v.01z"
      fill="url(#paint0_radial)"
    />
    <path
      d="M9.227 2.63V16.42a2.64 2.64 0 001.58 2.416 2.624 2.624 0 002.841-.488L27.626 4.337l.044-.05c.39-.46.597-1.048.585-1.652A2.637 2.637 0 0026.632.2c-.319-.132-.661-.2-1.006-.2H11.859c-.697 0-1.365.276-1.859.769a2.638 2.638 0 00-.773 1.86z"
      fill="#00E091"
    />
    <path
      d="M65.613 8.617a3.993 3.993 0 00-1.571-.303c-2.234 0-3.62 1.35-3.62 3.525v10.85h1.65v-8.261h2.962v-1.43h-2.998v-1.057c0-1.47.698-2.203 2.074-2.203.448-.006.893.074 1.311.237l.033.013.195-1.327-.017-.03-.019-.014zM44.038 8.694h2.275l-4.96 5.709 5.02 8.259h-2.15l-4.14-6.841-2.521 2.815v4.026h-1.844V8.694h1.843v7.413l6.477-7.413z"
      fill="#292B35"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.262 12.806c1.566 0 2.869.616 3.676 1.718v-1.517h1.65v9.682h-1.65v-1.528c-.813 1.115-2.113 1.726-3.676 1.726-2.775 0-4.869-2.21-4.869-5.07 0-2.858 2.094-5.011 4.869-5.011zm-3.234 5.003c0 2.044 1.459 3.58 3.396 3.58h-.014a3.503 3.503 0 002.513-1.043 3.52 3.52 0 001.015-2.528 3.46 3.46 0 00-1.007-2.513 3.44 3.44 0 00-2.507-1.01c-1.937 0-3.396 1.47-3.396 3.514zM66.034 17.862c0-2.922 2.09-5.043 4.97-5.043a4.619 4.619 0 013.638 1.567 4.94 4.94 0 01.97 4.048v.022h-7.847c.266 1.752 1.714 2.884 3.701 2.884a6.613 6.613 0 003.399-.89l.033-.016.291 1.509h-.016a7.042 7.042 0 01-3.644.958c-3.237 0-5.495-2.07-5.495-5.04zm1.758-.75h6.306V17.1c-.126-1.675-1.283-2.74-3.044-2.74-1.761 0-3.1 1.129-3.262 2.754z"
      fill="#292B35"
    />
    <path
      d="M83.061 12.82a3.74 3.74 0 00-3.184 1.577V13.02h-1.649v9.683h1.649v-5.477c0-1.653 1.24-2.862 2.946-2.862 1.706 0 2.695 1.005 2.695 2.831v5.494h1.635v-5.885c-.011-2.503-1.539-3.985-4.092-3.985z"
      fill="#292B35"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M96.5 13.187a4.622 4.622 0 011.636 1.2 4.934 4.934 0 01.964 4.047v.022h-7.845c.264 1.752 1.715 2.884 3.702 2.884a6.616 6.616 0 003.407-.879l.033-.016.291 1.498h-.017a7.052 7.052 0 01-3.638.944c-3.236 0-5.495-2.07-5.495-5.04 0-2.921 2.091-5.042 4.97-5.042a4.612 4.612 0 011.991.382zM91.282 17.1h6.32c-.127-1.675-1.297-2.754-3.059-2.754-1.761 0-3.1 1.129-3.261 2.754z"
      fill="#292B35"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(146.411 8.305 8.682) scale(26.0472 22.2936)"
      >
        <stop offset=".43" stop-color="#06A27E" />
        <stop offset=".58" stop-color="#28BE95" stop-opacity=".65" />
        <stop offset=".71" stop-color="#42D5A8" stop-opacity=".37" />
        <stop offset=".83" stop-color="#55E5B5" stop-opacity=".17" />
        <stop offset=".93" stop-color="#61EEBD" stop-opacity=".04" />
        <stop offset="1" stop-color="#65F2C0" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
