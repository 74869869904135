<template>
  <div class="start">
    <div class="start__header">
      <slot name="header" />
    </div>

    <div class="start__main">
      <slot name="main" />
    </div>

    <div class="start__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start'
}
</script>

<style lang="scss" scoped>
.start {
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  @include media-query(sm) {
    padding-top: 32px;
  }

  @include media-query(md) {
    padding-top: 72px;
  }

  &__header {
    flex: 1 0 198px;
    margin-left: auto;
    margin-right: auto;
    max-width: 515px;
    width: 100%;
  }

  &__main {
    flex: 0 0 auto;

    @include media-query(md) {
      flex: 1 0 180px;
    }
  }

  &__footer {
    margin: 0 auto;
    margin-top: 40px;
    width: 100%;
    max-width: 540px;
    flex: 1 1 auto;
  }
}
</style>
