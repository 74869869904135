<template>
  <component
    :is="tag"
    class="heading"
    :class="{
      'heading--1': h1,
      'heading--2': h2,
      'heading--3': h3,
      'heading--4': h4,
      'heading--5': h5,
      'heading--6': h6
    }"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    is: String,
    h1: Boolean,
    h2: Boolean,
    h3: Boolean,
    h4: Boolean,
    h5: Boolean,
    h6: Boolean
  },
  computed: {
    tag() {
      // ???
      const variants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']

      return this.is || variants.find((variant) => this[variant])
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  margin: 0;

  &--1 {
    color: #323b4b;
  }

  &--2 {
    font-size: 26px;
    line-height: 32px;
    color: #323b4b;
  }

  &--3 {
    font-size: 24px;
    color: #4e5d78;
  }

  &--4 {
    font-size: 22px;
    color: #4e5d78;
  }

  &--5 {
    font-size: 16px;
    color: #4e5d78;
  }

  &--6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #4e5d78;
  }
}
</style>
