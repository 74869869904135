<template>
  <div
    class="toast"
    :class="{
      'toast--error': error,
      'toast--success': success,
      'toast--warning': warning,
      'toast--info': info
    }"
  >
    <div class="toast__icon">
      <svg v-if="error" class="toast__svg" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9 .667A8.336 8.336 0 0 0 .667 9c0 4.6 3.733 8.333 8.333 8.333S17.333 13.6 17.333 9 13.6.667 9 .667Zm.833 12.5H8.167V11.5h1.666v1.667Zm0-3.334H8.167v-5h1.666v5Z"
          fill="currentColor"
        />
      </svg>

      <svg v-else-if="success" class="toast__svg" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9 .667A8.336 8.336 0 0 0 .667 9c0 4.6 3.733 8.333 8.333 8.333S17.333 13.6 17.333 9 13.6.667 9 .667Zm-1.667 12.5L3.167 9l1.175-1.175 2.991 2.983 6.325-6.325 1.175 1.184-7.5 7.5Z"
          fill="#3DBA4E"
        />
      </svg>

      <svg v-else-if="warning" class="toast__svg" width="20" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M.833 16.5h18.334L10 .667.833 16.5Zm10-2.5H9.167v-1.667h1.666V14Zm0-3.333H9.167V7.333h1.666v3.334Z"
          fill="#FCB017"
        />
      </svg>

      <svg v-else-if="info" class="toast__svg" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9 .667A8.336 8.336 0 0 0 .667 9c0 4.6 3.733 8.333 8.333 8.333S17.333 13.6 17.333 9 13.6.667 9 .667Zm.833 12.5H8.167v-5h1.666v5Zm0-6.667H8.167V4.833h1.666V6.5Z"
          fill="#6B78EB"
        />
      </svg>
    </div>

    <div class="toast__message">
      <P colorInherit><slot /></P>
    </div>
  </div>
</template>

<script>
import P from './P.vue'

export default {
  name: 'Toast',
  components: {
    P
  },
  props: {
    error: Boolean,
    info: Boolean,
    success: Boolean,
    warning: Boolean
  }
}
</script>

<style lang="scss" scoped>
.toast {
  border: 1px solid;
  padding: 14px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &__icon {
    flex: 0 0 auto;
  }

  &__message {
    text-align: left;
    margin-left: 8px;
    flex: 1 1 auto;
  }

  &__svg {
    display: block;
  }

  &--error {
    border-color: get-color(primary-4);
    color: get-color(primary-4);
    background-color: #fcdcdd;
  }

  &--success {
    border-color: get-color(primary-1);
    color: get-color(primary-1);
    background-color: #dcfae0;
  }

  &--warning {
    border-color: get-color(primary-3);
    color: get-color(text-1);
    background-color: #fdf1d3;
  }

  &--info {
    border-color: #6b78eb;
    color: #6b78eb;
    background-color: #eeeffc;
  }
}
</style>
