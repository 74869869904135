const iframeBus = {
  handlers: [],
  install(app, { src }) {
    const getMessage = (event) => {
      try {
        const { type, payload } = typeof event.data === 'string' ? JSON.parse(event.data) : {}

        const handler = this.handlers.find((item) => item.type === type)

        // TODO: fix eslint issue
        // eslint-disable-next-line no-unused-expressions
        !!handler && handler.callback(payload)
      } catch (error) {
        console.log(error)
      }
    }

    const on = (type, callback) => {
      this.handlers.push({ type, callback })
    }

    const post = (actionType) => {
      const action = {
        src,
        type: actionType
      }

      window.parent.postMessage(JSON.stringify(action), '*')
    }

    window.addEventListener('message', getMessage)

    app.config.globalProperties.$iframeBus = {
      post,
      on
    }
  }
}

export default iframeBus
