import { createApp } from 'vue'
import router from './router'
import { store } from './store'
import App from './App.vue'
import Maska from 'maska'
import iframeBus from './helpers/iframe-bus'
import copyText from '@meforma/vue-copy-to-clipboard'
import UUID from './helpers/uuid'
import NeuroId from './helpers/neuro-id'

createApp(App)
  .use(store)
  .use(router)
  .use(iframeBus, { src: 'kafene-direct' })
  .use(copyText)
  .use(Maska)
  .mixin(UUID)
  .mixin(NeuroId)
  .mount('#app')
