<template>
  <component
    class="button"
    :is="tag"
    :to="to"
    :href="href"
    :class="{
      'button--large': large,
      'button--wide': wide,
      'button--bold': bold,
      'button--expanded': expanded,
      'button--icon': icon,
      'button--inline': inline,
      'button--dimmed': dimmed,
      'button--link': link,
      'button--fixed': fixed,
      'button--loading': loading,
      'button--disabled': disabled,
      'button--light': light
    }"
    :target="href && blank ? `_blank` : null"
    ref="button"
    :data-element="dataElement"
    @click="onClick"
    :disabled="disabled"
  >
    <span v-if="$slots.icon && !reversed" class="button__icon button__icon--start">
      <slot name="icon" />
    </span>

    <slot />

    <span v-if="$slots.icon && reversed" class="button__icon button__icon--end">
      <slot name="icon" />
    </span>

    <span v-if="loading" class="button__icon button__icon--spinner">
      <Spinner />
    </span>
  </component>
</template>

<script>
import Spinner from './Spinner.vue'

export default {
  inheritAttrs: false,
  props: {
    large: Boolean,
    wide: Boolean,
    bold: Boolean,
    expanded: Boolean,
    icon: Boolean,
    inline: Boolean,
    dimmed: Boolean,
    link: Boolean,
    fixed: Boolean,
    reversed: Boolean,
    loading: Boolean,
    blank: Boolean,
    disabled: Boolean,
    light: Boolean,
    href: String,
    to: String,
    dataElement: String
  },
  components: {
    Spinner
  },
  computed: {
    tag() {
      if (this.href) {
        return 'a'
      }

      return 'button'
    }
  },
  methods: {
    setFocus() {
      this.$nextTick(() => {
        this.$refs.button.focus()
      })
    },
    onClick(e) {
      if (this.disabled) {
        e.preventDefault()
        return
      }

      if (this.to) {
        this.$router.push({ path: this.to })
        return
      }

      if (this.href) {
        return
      }

      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  $this: &;

  font-family: 'Inter', sans-serif;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 12px 24px;
  border-radius: 4px;
  background-color: get-color(primary-1);
  text-decoration: none;

  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  cursor: pointer;

  &__icon {
    &--start {
      margin-right: 24px;
    }

    &--end {
      margin-left: 24px;
    }

    &--spinner {
      display: inline-block;
      margin-left: 18px;
    }
  }

  &--large {
    font-size: 16px;
    line-height: 20px;
    padding: 18px 40px;
  }

  &--wide {
    padding-left: 40px;
    padding-right: 40px;
  }

  &--expanded {
    display: flex;
    width: 100%;
  }

  &--icon {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  &--dimmed {
    background: #f3f3f3;
    border-radius: 100px;
    font-weight: 500;
    font-size: 16px;
    padding: 5px 13px;
    color: #000000;
  }

  &--light {
    background: rgba(get-color(primary-1), 0.2);
    color: get-color(primary-1);
  }

  &--link {
    padding-left: 0;
    padding-right: 0;
    background-color: rgba(0, 0, 0, 0);
    color: #22c58b;
    font-weight: 500;

    &#{$this}--disabled {
      color: get-color(bg-1);
      background-color: rgba(0, 0, 0, 0);
    }
  }

  &--bold {
    font-weight: 600;
  }

  &--inline {
    display: inline;
    padding: 0;
  }

  &--fixed {
    min-width: 184px;
  }

  &--disabled {
    cursor: auto;
    background-color: get-color(bg-1);
  }
}
</style>
