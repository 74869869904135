import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const applicationResult = (status) => {
  switch (status) {
    case 'approved':
      return 'approved'
    case 'denied':
    case 'cancelled':
      return 'denied'
    case 'manual':
      return 'set_up_review'
    default:
      return ''
  }
}

const NeuroId = {
  async beforeCreate() {
    const { nid } = window
    const router = useRouter()
    const store = useStore()
    const { path } = router.currentRoute.value

    if (!(window.location.hostname === '<%= VUE_APP_PRODUCTION_HOSTNAME %>')) {
      // nid('setDebug', true)
    }

    switch (path) {
      case '/about-you':
        nid('start', 'new_application')
        nid('setVariable', 'funnel', 'online')
        break
      case '/approval':
      case '/denied':
      case '/manual-review':
        nid('setUserId', store.getters['application/getApplicationId'])
        await nid('sendData', () => {})
        nid('setVariable', 'application_result', applicationResult(store.getters['application/getStatus']))
        nid('applicationSubmit', 'customer_application')
        await nid('sendData', () => {})
        nid('stop')
        break
      default:
        break
    }
  }
}

export default NeuroId
