const numberFormat = (lang, options) => new Intl.NumberFormat(lang, options)

const mask = (text, visible = 4) =>
  text &&
  text
    .slice(0, text.length - visible)
    .split('')
    .map(() => '*')
    .join('') + text.slice(text.length - visible, text.length)

const repeatedCharMask = (number, every, char) =>
  number
    .toString()
    .split('')
    .reverse()
    // eslint-disable-next-line eqeqeq
    .reduce((acc, cur, i) => (i % every == 0 ? [...acc, char, cur] : [...acc, cur]), [])
    .slice(1)
    .reverse()
    .join('')

const moneyMask = (amount) => {
  const integer = amount.toString().split('.')[0]
  const decimal = amount.toString().split('.')[1] || ''

  return `$${repeatedCharMask(integer, 3, ',')}${decimal && `.${(decimal + 0).slice(0, 2)}`}`
}

const unformatMoney = (s) =>
  typeof s === 'string' && s !== '' ? parseFloat(s.replace('$', '').trim().replaceAll(',', '')) : 0

const emailInputMaska = {
  mask: 'G*',
  tokens: {
    G: {
      pattern: /[a-z0-9!#$%&'*+-/=?^_`{|}~@]/,
      lowercase: true
    }
  }
}

const moneyInputMaska = {
  mask: ['$ H##', '$ H,###'],
  tokens: {
    H: {
      pattern: /[1-9]/
    }
  }
}

const moneyInputMaskaDecimal = {
  mask: [
    '$ 0.0H',
    '$ 0.H#',
    '$ H.##',
    '$ H#.##',
    '$ H##.##',
    '$ H,###.##',
    '$ H#,###.##',
    '$ H##,###.##',
    '$ H,###,###.##',
    '$ H#,###,###.##',
    '$ H##,###,###.##',
    '$ H,###,###,###.##',
    '$ H#,###,###,###.##',
    '$ H##,###,###,###.##'
  ],
  tokens: {
    H: {
      pattern: /[1-9]/
    }
  }
}

const toCurrency = (amount) =>
  numberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }).format(amount)

const formatDateString = (date) => {
  return Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }).format(date)
}

export {
  mask,
  repeatedCharMask,
  moneyMask,
  moneyInputMaska,
  moneyInputMaskaDecimal,
  unformatMoney,
  toCurrency,
  emailInputMaska,
  formatDateString
}
